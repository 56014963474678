import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Typography, Snackbar, Alert } from '@mui/material';
import ContentDeliveryCalendar from './ContentDeliveryCalendar';
import MainCard from "../../components/cards/MainCard";
import { useGetDeliveryRequestByIdQuery } from '../../store/services/delivery-service';

const DeliveryCalendarPage = () => {
    const { tenancyId, deliveryGroupId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    // Get preset data from location state if available
    const presetData = location.state?.preset;
    const startDate = location.state?.startDate;

    // Determine mode based on the URL and presence of preset data
    const isCreateMode = location.pathname.includes('/calendar-create/');
    const isPresetMode = location.pathname.includes('/calendar-preset/');

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // Handle closing the snackbar
    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Show notification 
    const showNotification = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    // Validate preset data is available when in preset mode
    useEffect(() => {
        if (isPresetMode && (!presetData || !startDate)) {
            showNotification('Missing template data or start date', 'error');
            // Redirect back to delivery page after error
            setTimeout(() => navigate('/delivery'), 2000);
        }
    }, [isPresetMode, presetData, startDate, navigate]);

    // Handle going back to the previous page or navigation after success
    const handleClose = (success = false) => {
        if (success) {
            navigate('/delivery', {
                state: {
                    notification: {
                        message: `Delivery request ${isCreateMode || isPresetMode ? 'created' : 'updated'} successfully`,
                        severity: 'success'
                    }
                }
            });
        } else {
            navigate(-1);
        }
    };

    // Determine the mode to pass to ContentDeliveryCalendar
    const calendarMode = isPresetMode ? 'preset' : (isCreateMode ? 'create' : 'edit');

    return (
        <>
            <ContentDeliveryCalendar
                mode={calendarMode}
                deliveryGroupId={!isCreateMode && !isPresetMode ? deliveryGroupId : undefined}
                onClose={handleClose}
                showNotification={showNotification}
                presetData={presetData}
                startDate={startDate}
            />
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default DeliveryCalendarPage;