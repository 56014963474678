import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DELIVERY_API_URL } from "config";

const baseQuery = fetchBaseQuery({
    baseUrl: DELIVERY_API_URL,
    prepareHeaders: (headers) => {
        const user = localStorage.getItem("user");

        if (user) {
            let userToken = JSON.parse(user).token;
            headers.set("authorization", `Bearer ${userToken}`);
        }
        return headers;
    },
    credentials: "omit"
});

export const deliveryApi = createApi({
    reducerPath: "deliveryApi",
    baseQuery: baseQuery,
    tagTypes: ['DeliveryRequest', 'PresetDeliveryRequest'],
    endpoints: (builder) => ({
        // Preset Delivery Requests
        getPresetRequests: builder.query({
            query: ({ perPage = 10, page = 1, searchTerm = '' }) =>
                `/delivery/preset?perPage=${perPage}&page=${page}&search=${searchTerm}`,
            transformResponse: (response) => ({
                presets: response.data || [],
                totalRecords: response.totalRecords || 0,
                page: response.page || 1,
                pageSize: response.pageSize || 10
            }),
            providesTags: (result) =>
                result?.presets
                    ? [
                        ...result.presets.map(({ id }) => ({ type: 'PresetDeliveryRequest', id })),
                        { type: 'PresetDeliveryRequest', id: 'LIST' }
                    ]
                    : [{ type: 'PresetDeliveryRequest', id: 'LIST' }]
        }),

        getPresetRequestById: builder.query({
            query: (id) => `/delivery/preset/${id}`,
            transformResponse: (response) => response,
            providesTags: (result, error, id) => [{ type: 'PresetDeliveryRequest', id }]
        }),

        createPresetRequest: builder.mutation({
            query: (presetData) => ({
                url: '/delivery/preset',
                method: 'POST',
                body: presetData
            }),
            invalidatesTags: [{ type: 'PresetDeliveryRequest', id: 'LIST' }]
        }),

        updatePresetRequest: builder.mutation({
            query: ({ id, presetData }) => ({
                url: `/delivery/preset/${id}`,
                method: 'PUT',
                body: presetData
            }),
            invalidatesTags: (result, error, { id }) => [
                { type: 'PresetDeliveryRequest', id },
                { type: 'PresetDeliveryRequest', id: 'LIST' }
            ]
        }),

        deletePresetRequest: builder.mutation({
            query: (id) => ({
                url: `/delivery/preset/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: 'PresetDeliveryRequest', id: 'LIST' }]
        }),

        assignPresetRequest: builder.mutation({
            query: ({ tenancyId, presetId, assignData }) => ({
                url: `/delivery/tenancy/${tenancyId}/preset/${presetId}`,
                method: 'POST',
                body: assignData
            }),
            invalidatesTags: (result, error, { tenancyId }) => [
                { type: 'DeliveryRequest', id: `tenancy-${tenancyId}` }
            ]
        }),

        // Delivery Requests
        getDeliveryRequests: builder.query({
            query: ({
                tenancyId,
                perPage = 10,
                page = 1,
                statusFilter = '',
                typeFilter = '',
                dateRangeStart = '',
                dateRangeEnd = ''
            }) => {
                let url = `/delivery/tenancy/${tenancyId}/request?page=${page}&perPage=${perPage}`;

                if (statusFilter) url += `&statusFilter=${statusFilter}`;
                if (typeFilter) url += `&typeFilter=${typeFilter}`;
                if (dateRangeStart) url += `&dateRangeStart=${dateRangeStart}`;
                if (dateRangeEnd) url += `&dateRangeEnd=${dateRangeEnd}`;

                return url;
            },
            transformResponse: (response) => ({
                requests: response.data || [],
                items: response.items || {},
                totalRecords: response.totalRecords || 0,
                page: response.page || 1,
                pageSize: response.pageSize || 10
            }),
            providesTags: (result, error, { tenancyId }) =>
                result?.requests
                    ? [
                        ...result.requests.map(({ id }) => ({ type: 'DeliveryRequest', id })),
                        { type: 'DeliveryRequest', id: `tenancy-${tenancyId}` }
                    ]
                    : [{ type: 'DeliveryRequest', id: `tenancy-${tenancyId}` }]
        }),

        getDeliveryRequestById: builder.query({
            query: ({ tenancyId, requestId }) => `/delivery/tenancy/${tenancyId}/request/${requestId}`,
            transformResponse: (response) => ({
                request: response.data,
                items: response.items || []
            }),
            providesTags: (result, error, { requestId }) => [{ type: 'DeliveryRequest', id: requestId }]
        }),

        createDeliveryRequest: builder.mutation({
            query: ({ tenancyId, requestData }) => ({
                url: `/delivery/tenancy/${tenancyId}/request`,
                method: 'POST',
                body: requestData
            }),
            invalidatesTags: (result, error, { tenancyId }) => [
                { type: 'DeliveryRequest', id: `tenancy-${tenancyId}` }
            ]
        }),

        updateDeliveryRequest: builder.mutation({
            query: ({ tenancyId, requestId, requestData }) => ({
                url: `/delivery/tenancy/${tenancyId}/request/${requestId}`,
                method: 'PUT',
                body: requestData
            }),
            invalidatesTags: (result, error, { tenancyId, requestId }) => [
                { type: 'DeliveryRequest', id: requestId },
                { type: 'DeliveryRequest', id: `tenancy-${tenancyId}` }
            ]
        }),

        deleteDeliveryRequest: builder.mutation({
            query: ({ tenancyId, requestId }) => ({
                url: `/delivery/tenancy/${tenancyId}/request/${requestId}`,
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, { tenancyId }) => [
                { type: 'DeliveryRequest', id: `tenancy-${tenancyId}` }
            ]
        })
    })
});

export const {
    // Preset requests hooks
    useGetPresetRequestsQuery,
    useLazyGetPresetRequestsQuery,
    useGetPresetRequestByIdQuery,
    useCreatePresetRequestMutation,
    useUpdatePresetRequestMutation,
    useDeletePresetRequestMutation,
    useAssignPresetRequestMutation,

    // Delivery requests hooks
    useGetDeliveryRequestsQuery,
    useLazyGetDeliveryRequestsQuery,
    useGetDeliveryRequestByIdQuery,
    useCreateDeliveryRequestMutation,
    useUpdateDeliveryRequestMutation,
    useDeleteDeliveryRequestMutation
} = deliveryApi;