import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    CircularProgress,
    Typography,
    Dialog,
    DialogContent,
    IconButton,
    useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useGetSimulationByIdQuery } from "../../store/services/simulation-service";
import { useModal } from '../../components/Modal/ModalContext';

// Styled components for email display
const EmailContainer = styled(Box)(({ theme }) => ({
    height: '100%',
    overflowY: 'auto',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    position: 'relative', // Enable positioning for potential overlay
    cursor: 'default', // Show default cursor instead of pointer
}));

const EmailHeader = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

const EmailSubject = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.25rem',
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary,
}));

const EmailFrom = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '& .display-name': {
        fontWeight: 500,
    },
}));

const EmailBody = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'inherit',
    pointerEvents: 'none', // Disable all pointer events for the email body
    '& a': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        pointerEvents: 'none', // Specifically disable links
    },
    '& img': {
        maxWidth: '100%',
        height: 'auto',
    },
    '& button, & input, & select, & textarea': {
        pointerEvents: 'none', // Disable interactive form elements
    },
    // Override HTML content styling for dark mode
    '& div': {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
    },
    '& p, & span, & h1, & h2, & h3, & h4, & h5, & h6, & li': {
        color: theme.palette.text.primary,
    },
    '& *': {
        pointerEvents: 'none', // Ensure all child elements are non-clickable
    },
}));

// Tab components for the modal version
const TabContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -35,
    left: 0,
    display: 'flex',
    alignItems: 'flex-start',
    zIndex: -1,
}));

const PreviewTab = styled(Box)(({ theme, index = 0 }) => ({
    width: '140px',
    height: '50px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    boxShadow: `
        0 5px 5px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)'},
        5px 2px 5px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.35)'}
    `,
    transform: `translateX(${index * -15}%)`,
    zIndex: 3 - index,
    '& .label-text': {
        transform: index === 0
            ? 'translateY(7px)'
            : `translate(${7.2}%, 7px)`,
        width: '100%',
        textAlign: 'center',
        color: theme.palette.mode === 'light' ? '#000' : '#000',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 8px'
    }
}));

const ShadowOverlay = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: -10,
    left: 0,
    right: 0,
    height: 20,
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.08) 40%, transparent 100%)',
    zIndex: 0,
    pointerEvents: 'none',
}));

// Function to determine difficulty text
const getDifficultyText = (rating) => {
    switch (rating) {
        case 1: return 'Easy';
        case 2: return 'Medium';
        case 3: return 'Hard';
        case 4: return 'Expert';
        default: return 'Medium';
    }
};

// Wrapper component to apply theme styles to HTML content
const ThemedHtmlContent = ({ content, theme }) => {
    const htmlContentStyle = {
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        fontFamily: theme.typography.fontFamily,
        pointerEvents: 'none', // Disable clicks at the container level
    };

    // For maximum compatibility, we'll also add an overlay to catch any clicks
    return (
        <Box sx={{ position: 'relative' }}>
            <div
                style={htmlContentStyle}
                dangerouslySetInnerHTML={{ __html: content || '<p>No content available</p>' }}
            />
            {/* Invisible overlay to prevent any interaction */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    cursor: 'default',
                }}
                onClick={(e) => e.preventDefault()}
            />
        </Box>
    );
};

ThemedHtmlContent.propTypes = {
    content: PropTypes.string,
    theme: PropTypes.object.isRequired
};

// The simulation preview content component
const CalendarSimulationPreviewContent = ({ simulationData, isLoading, error }) => {
    const theme = useTheme();

    return (
        <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
            {isLoading && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <CircularProgress />
                </Box>
            )}

            {error && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    color: 'error.main'
                }}>
                    <Typography>Error loading simulation preview</Typography>
                </Box>
            )}

            {!isLoading && !error && !simulationData && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>
                    <Typography>No simulation data available</Typography>
                </Box>
            )}

            {!isLoading && !error && simulationData && (
                <EmailContainer>
                    <EmailHeader>
                        <EmailSubject variant="h5">
                            {simulationData.emailSubject || "No Subject"}
                        </EmailSubject>
                        <EmailFrom variant="body2">
                            <span className="display-name">{simulationData.emailDisplayName || ""}</span>
                            <span className="email-address"> &lt;{simulationData.emailFromAddress || ''}&gt;</span>
                        </EmailFrom>
                    </EmailHeader>
                    <EmailBody>
                        <ThemedHtmlContent content={simulationData.content} theme={theme} />
                    </EmailBody>
                </EmailContainer>
            )}
        </Box>
    );
};

// The modal version for the ContentDeliveryCalendar
const CalendarSimulationPreviewModal = ({ simulationId, open, onClose, isStandalone = false }) => {
    const { setActiveModal } = useModal();
    const theme = useTheme();

    const { data: simData, isLoading, error } = useGetSimulationByIdQuery({
        simId: simulationId,
        includes: ''
    }, { skip: !simulationId });

    const handleClose = () => {
        if (setActiveModal) {
            setActiveModal(null);
        }
        if (onClose) {
            onClose();
        }
    };

    return (
        <Box sx={{ position: 'relative' }}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        width: '50%', // Match SimulationTable width
                        height: '80%', // Match SimulationTable height
                        margin: '0 auto',
                        backgroundColor: theme.palette.background.default,
                        borderRadius: '8px',
                        overflow: 'hidden',
                        position: 'relative',
                        zIndex: (theme) => theme.zIndex.modal + 1
                    }
                }}
                sx={{
                    '& .MuiDialog-container': {
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100vh'
                    },
                    '& .MuiPaper-root': {
                        m: 0
                    },
                    '& .MuiBackdrop-root': {
                        display: isStandalone ? 'flex' : 'none',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }}
            >
                {open && (
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: -45,
                            bgcolor: theme.palette.background.default,
                            boxShadow: 2,
                            '&:hover': {
                                bgcolor: theme.palette.background.default,
                                opacity: 0.9
                            },
                            width: 40,
                            height: 40,
                            zIndex: (theme) => theme.zIndex.modal + 1
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                )}

                <DialogContent
                    sx={{
                        p: 0,
                        height: '100%',
                        width: '100%',
                        overflow: 'auto',
                        position: 'relative',
                        zIndex: 2,
                        backgroundColor: theme.palette.background.paper
                    }}
                >
                    <CalendarSimulationPreviewContent
                        simulationData={simData}
                        isLoading={isLoading}
                        error={error}
                    />
                </DialogContent>

                <ShadowOverlay />
            </Dialog>
        </Box>
    );
};

// The standalone version for the SimulationTable
const CalendarSimulationPreview = ({ simulationId }) => {
    const { data: simData, isLoading, error } = useGetSimulationByIdQuery({
        simId: simulationId,
        includes: ''
    }, { skip: !simulationId });

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <CalendarSimulationPreviewContent
                simulationData={simData}
                isLoading={isLoading}
                error={error}
            />
        </Box>
    );
};

CalendarSimulationPreview.propTypes = {
    simulationId: PropTypes.string.isRequired
};

CalendarSimulationPreviewModal.propTypes = {
    simulationId: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isStandalone: PropTypes.bool
};

CalendarSimulationPreviewContent.propTypes = {
    simulationData: PropTypes.object,
    isLoading: PropTypes.bool,
    error: PropTypes.any
};

export { CalendarSimulationPreviewModal, CalendarSimulationPreviewContent };
export default CalendarSimulationPreview;