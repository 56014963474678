import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Chip
} from '@mui/material';
import { useCreatePresetRequestMutation } from '../../store/services/delivery-service';
import MainCard from "../../components/cards/MainCard";


const MODAL_BORDER_RADIUS = '8px'; // Match your app's style

/**
 * Modal component for exporting calendar items to a preset template
 */
const ExportToPresetModal = ({ 
    open, 
    onClose, 
    calendarItems, 
    showNotification 
}) => {
    const [presetName, setPresetName] = useState('');
    const [presetItemsWithOffset, setPresetItemsWithOffset] = useState([]);
    const [exportAttempted, setExportAttempted] = useState(false);
    
    // API mutation hook
    const [createPresetRequest, { isLoading: isCreatingPreset }] = useCreatePresetRequestMutation();

    // Calculate day offsets when the modal opens with calendar items
    React.useEffect(() => {
        if (open && calendarItems.length > 0) {
            // Sort items by date
            const sortedItems = [...calendarItems].sort((a, b) => 
                new Date(a.scheduledFor) - new Date(b.scheduledFor)
            );

            // Find the earliest date
            const earliestDate = new Date(sortedItems[0].scheduledFor);
            
            // Calculate day offsets for each item relative to the earliest date
            const itemsWithOffset = sortedItems.map(item => {
                const itemDate = new Date(item.scheduledFor);
                const dayOffset = Math.round((itemDate - earliestDate) / (24 * 60 * 60 * 1000));
                
                return {
                    ...item,
                    scheduleDayOffset: dayOffset
                };
            });

            setPresetItemsWithOffset(itemsWithOffset);
            setPresetName('');
            setExportAttempted(false);
        }
    }, [open, calendarItems]);

    const handleCreatePreset = async () => {
        setExportAttempted(true);
        
        if (!presetName.trim()) {
            return;
        }

        try {
            const user = JSON.parse(localStorage.getItem('user'));
            const userId = user?.email || '';

            const presetData = {
                name: presetName,
                createdById: userId,
                presetItems: presetItemsWithOffset.map(item => ({
                    contentId: item.contentId,
                    contentType: item.contentType,
                    scheduleDayOffset: item.scheduleDayOffset
                }))
            };

            await createPresetRequest(presetData).unwrap();
            showNotification('Preset template created successfully', 'success');
            onClose();
        } catch (error) {
            console.error('Error creating preset:', error);
            showNotification(`Error creating preset: ${error.data?.error || 'Unknown error'}`, 'error');
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: { borderRadius: MODAL_BORDER_RADIUS },
                sx: {
                    bgcolor: 'background.default'
                }
            }}
        >
            <DialogTitle>Export Calendar to Preset Template</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 3, mt: 2 }}>
                    <TextField
                        label="Preset Template Name"
                        value={presetName}
                        onChange={(e) => setPresetName(e.target.value)}
                        fullWidth
                        required
                        error={exportAttempted && !presetName.trim()}
                        helperText={exportAttempted && !presetName.trim() ? "Name is required" : ""}
                    />
                </Box>
                
                <Typography variant="h6" gutterBottom>
                    Content Items ({presetItemsWithOffset.length})
                </Typography>
                
                <MainCard
                    content={true}
                    boxShadow={true}
                    border={false}
                    sx={{ bgcolor: 'background.default' }}
                    >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Send Day</TableCell>
                                <TableCell>Content Type</TableCell>
                                <TableCell>Content Name</TableCell>
                                <TableCell>Original Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {presetItemsWithOffset.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Chip 
                                            label={`Day ${item.scheduleDayOffset}`} 
                                            color={item.scheduleDayOffset === 0 ? "primary" : "default"}
                                            variant={item.scheduleDayOffset === 0 ? "filled" : "outlined"}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Chip 
                                            label={item.contentType} 
                                            color={item.contentType === 'Simulation' ? 'primary' : 'warning'}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>{item.contentId}</TableCell>
                                    <TableCell>{new Date(item.scheduledFor).toLocaleDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </MainCard>
                
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                        Note: Day offsets are calculated relative to the earliest item in your calendar (Day 0).
                        When this preset is used, the user will choose a start date, and all items will be scheduled
                        based on their offset from that date.
                    </Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    variant="contained"
                    color="secondary"
                >

                    Cancel</Button>
                <Button
                    onClick={handleCreatePreset}
                    variant="contained"
                    color="primary"
                    disabled={isCreatingPreset}
                >
                    {isCreatingPreset ? (
                        <CircularProgress size={24} />
                    ) : (
                        'Create Preset Template'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ExportToPresetModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    calendarItems: PropTypes.array.isRequired,
    showNotification: PropTypes.func.isRequired
};

export default ExportToPresetModal;