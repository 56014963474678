import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Divider,
    IconButton,
    CircularProgress,
    Snackbar,
    Alert,
    Card,
    CardContent
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ArrowBack as BackIcon
} from '@mui/icons-material';

import {
    useGetPresetRequestByIdQuery,
    useCreatePresetRequestMutation,
    useUpdatePresetRequestMutation
} from '../../store/services/delivery-service';

const initializeNewItem = () => ({
    id: '', // Will be filled by backend for new items
    contentId: '',
    contentType: 'Simulation',
    scheduleDayOffset: 0
});

const PresetDeliveryForm = ({ isEditing = false }) => {
    const navigate = useNavigate();
    const { presetId } = useParams();

    const [formData, setFormData] = useState({
        name: '',
        createdById: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).userId : '',
        presetItems: [initializeNewItem()]
    });

    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    // Queries and mutations
    const { data: presetData, isLoading: isLoadingPreset } = useGetPresetRequestByIdQuery(
        presetId,
        { skip: !isEditing || !presetId }
    );

    const [createPresetRequest, { isLoading: isCreating }] = useCreatePresetRequestMutation();
    const [updatePresetRequest, { isLoading: isUpdating }] = useUpdatePresetRequestMutation();

    // Initialize form with existing data when editing
    useEffect(() => {
        if (isEditing && presetData) {
            setFormData({
                name: presetData.name || '',
                createdById: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).userId : '',
                presetItems: presetData.items && presetData.items.length > 0
                    ? presetData.items.map(item => ({
                        id: item.id,
                        contentId: item.contentId || '',
                        contentType: item.contentType || 'Simulation',
                        scheduleDayOffset: item.scheduleDayOffset
                    }))
                    : [initializeNewItem()]
            });
        }
    }, [isEditing, presetData]);

    // Handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle item input changes
    const handleItemChange = (index, field, value) => {
        setFormData(prev => {
            const updatedItems = [...prev.presetItems];
            updatedItems[index] = {
                ...updatedItems[index],
                [field]: value
            };
            return {
                ...prev,
                presetItems: updatedItems
            };
        });
    };

    // Add new item
    const handleAddItem = () => {
        setFormData(prev => ({
            ...prev,
            presetItems: [...prev.presetItems, initializeNewItem()]
        }));
    };

    // Remove item
    const handleRemoveItem = (index) => {
        setFormData(prev => {
            const updatedItems = [...prev.presetItems];
            updatedItems.splice(index, 1);
            return {
                ...prev,
                presetItems: updatedItems.length > 0 ? updatedItems : [initializeNewItem()]
            };
        });
    };

    // Validate form
    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        formData.presetItems.forEach((item, index) => {
            if (!item.contentId.trim()) {
                newErrors[`item_${index}_contentId`] = 'Content ID is required';
            }

            if (item.scheduleDayOffset < 0) {
                newErrors[`item_${index}_scheduleDayOffset`] = 'Schedule day offset must be 0 or greater';
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setSnackbar({
                open: true,
                message: 'Please fix the errors in the form',
                severity: 'error'
            });
            return;
        }

        try {
            if (isEditing) {
                // Handle update
                const itemUpdates = [];
                const newItems = [];
                const itemIdsToRemove = [];

                // Original items from API (if available)
                const originalItems = presetData?.items || [];

                // Process existing items
                originalItems.forEach(originalItem => {
                    const matchingItem = formData.presetItems.find(item =>
                        item.id === originalItem.id
                    );

                    if (!matchingItem) {
                        // Item was removed
                        itemIdsToRemove.push(originalItem.id);
                    } else {
                        // Item was potentially updated
                        const update = {
                            itemId: originalItem.id
                        };

                        let hasChanges = false;

                        if (matchingItem.contentId !== originalItem.contentId) {
                            update.contentId = matchingItem.contentId;
                            hasChanges = true;
                        }

                        if (matchingItem.contentType !== originalItem.contentType) {
                            update.contentType = matchingItem.contentType;
                            hasChanges = true;
                        }

                        if (matchingItem.scheduleDayOffset !== originalItem.scheduleDayOffset) {
                            update.scheduleDayOffset = matchingItem.scheduleDayOffset;
                            hasChanges = true;
                        }

                        if (hasChanges) {
                            itemUpdates.push(update);
                        }
                    }
                });

                // Find new items
                formData.presetItems.forEach(item => {
                    if (!item.id) {
                        newItems.push({
                            contentId: item.contentId,
                            contentType: item.contentType,
                            scheduleDayOffset: item.scheduleDayOffset
                        });
                    }
                });

                const updateDto = {
                    presetId: presetId,
                    name: formData.name,
                    updatedById: formData.createdById,
                    itemUpdates,
                    newItems,
                    itemIdsToRemove
                };

                await updatePresetRequest({
                    id: presetId,
                    presetData: updateDto
                }).unwrap();

                setSnackbar({
                    open: true,
                    message: 'Preset template updated successfully',
                    severity: 'success'
                });
            } else {
                // Handle create
                const requestDto = {
                    name: formData.name,
                    createdById: formData.createdById,
                    presetItems: formData.presetItems.map(item => ({
                        contentId: item.contentId,
                        contentType: item.contentType,
                        scheduleDayOffset: item.scheduleDayOffset
                    }))
                };

                await createPresetRequest(requestDto).unwrap();

                setSnackbar({
                    open: true,
                    message: 'Preset template created successfully',
                    severity: 'success'
                });
            }

            // Navigate back after short delay
            setTimeout(() => {
                navigate('/delivery');
            }, 1500);
        } catch (err) {
            setSnackbar({
                open: true,
                message: `Failed to ${isEditing ? 'update' : 'create'} preset template: ${err.data?.message || 'Unknown error'}`,
                severity: 'error'
            });
        }
    };

    if (isEditing && isLoadingPreset) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton
                    onClick={() => navigate('/delivery')}
                    sx={{ mr: 2 }}
                >
                    <BackIcon />
                </IconButton>
                <Typography variant="h5" component="h1">
                    {isEditing ? 'Edit Preset Template' : 'Create Preset Template'}
                </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Template Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            required
                        />
                    </Grid>
                </Grid>

                <Box sx={{ my: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Preset Items
                    </Typography>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                        Specify the content items and delivery schedule offsets for this preset template.
                        The schedule day offset determines how many days after assignment each item will be delivered.
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    {formData.presetItems.map((item, index) => (
                        <Card key={index} variant="outlined" sx={{ mb: 3 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <Typography variant="subtitle1" component="h3">
                                            Item {index + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleRemoveItem(index)}
                                            disabled={formData.presetItems.length <= 1}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Content ID"
                                            value={item.contentId}
                                            onChange={(e) => handleItemChange(index, 'contentId', e.target.value)}
                                            error={!!errors[`item_${index}_contentId`]}
                                            helperText={errors[`item_${index}_contentId`]}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>Content Type</InputLabel>
                                            <Select
                                                value={item.contentType}
                                                label="Content Type"
                                                onChange={(e) => handleItemChange(index, 'contentType', e.target.value)}
                                            >
                                                <MenuItem value="Simulation">Simulation</MenuItem>
                                                <MenuItem value="Course">Course</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            label="Schedule Day Offset"
                                            value={item.scheduleDayOffset}
                                            onChange={(e) => handleItemChange(index, 'scheduleDayOffset', parseInt(e.target.value))}
                                            error={!!errors[`item_${index}_scheduleDayOffset`]}
                                            helperText={errors[`item_${index}_scheduleDayOffset`] || "Days after assignment"}
                                            InputProps={{ inputProps: { min: 0 } }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}

                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddItem}
                    >
                        Add Item
                    </Button>
                </Box>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/delivery')}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isCreating || isUpdating}
                    >
                        {(isCreating || isUpdating) ? (
                            <CircularProgress size={24} />
                        ) : (
                            isEditing ? 'Update' : 'Create'
                        )}
                    </Button>
                </Box>
            </form>

            {/* Snackbar notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

PresetDeliveryForm.propTypes = {
    isEditing: PropTypes.bool
};

export default PresetDeliveryForm;