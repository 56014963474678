import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';

// assets
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getTextColor } from 'utils/colorUtils';

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

const NavCollapse = ({ menu, level }) => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const { drawerOpen } = useSelector((state) => state.menu);
    const { pathname } = useLocation();

    const handleClick = () => {
        setOpen(!open);
    };

    // Check if any child route is active to auto-expand the parent
    useEffect(() => {
        if (menu && menu.children) {
            // Recursively check if current path matches any child or grandchild
            const checkActiveChild = (items) => {
                if (!Array.isArray(items)) return false;

                for (let i = 0; i < items.length; i++) {
                    const item = items[i];
                    if (!item) continue;

                    // Direct match
                    if (item.url && pathname.includes(item.url)) {
                        return true;
                    }

                    // Check nested children (for multi-level menu)
                    if (item.children && Array.isArray(item.children)) {
                        if (checkActiveChild(item.children)) {
                            return true;
                        }
                    }
                }
                return false;
            };

            // If any child is active, expand this collapse
            if (checkActiveChild(menu.children)) {
                setOpen(true);
            }
        }
    }, [pathname, menu]);

    const textColor = 'text.primary';
    const iconSelectedColor = theme.palette.secondary.main;
    const iconColor = theme.palette.secondary.light;
    const hoverColor = theme.palette.secondary.lighter;

    // Safety check for menu object
    if (!menu || typeof menu !== 'object') {
        return null;
    }

    const Icon = menu.icon;
    const menuIcon = menu.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem', color: iconColor }} /> : null;

    // Add safety checks for children array
    const safeChildren = menu.children && Array.isArray(menu.children) ? menu.children : [];

    const menus = safeChildren.map((item) => {
        if (!item || !item.type) {
            return null; // Skip invalid items
        }

        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id || 'error-key'} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    }).filter(Boolean); // Filter out null items

    // If there are no valid menu items after filtering, don't render anything
    if (menus.length === 0) {
        return null;
    }

    return (
        <>
            <ListItemButton
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? `${level * 28}px` : 1.5,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    marginLeft: 1,
                    marginRight: 1,
                    borderRadius: theme.borderRadius ? theme.borderRadius.sm : '8px',
                    ...(drawerOpen && {
                        '&:hover': {
                            bgcolor: hoverColor,
                            '& .MuiTypography-root': {
                                color: getTextColor(hoverColor),
                            },
                        }
                    }),
                    ...(!drawerOpen && {
                        '&:hover': {
                            bgcolor: 'transparent'
                        }
                    })
                }}
                onClick={handleClick}
            >
                {menuIcon && (
                    <ListItemIcon
                        sx={{
                            minWidth: 28,
                            color: textColor,
                            ...(!drawerOpen && {
                                borderRadius: theme.borderRadius ? theme.borderRadius.sm : '8px',
                                width: 36,
                                height: 36,
                                alignItems: 'center',
                                justifyContent: 'center',
                                '&:hover': {
                                    bgcolor: hoverColor
                                }
                            })
                        }}
                    >
                        {menuIcon}
                    </ListItemIcon>
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <ListItemText
                        primary={
                            <Typography variant="h6" color={textColor}>
                                {menu.title}
                            </Typography>
                        }
                    />
                )}
                {(drawerOpen || (!drawerOpen && level !== 1)) && (open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {drawerOpen && (
                    <List component="div" disablePadding sx={{ position: 'relative' }}>
                        {menus}
                    </List>
                )}
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;