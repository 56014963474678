import { useEffect, useMemo, useState } from "react";
import {
    Box,
    Checkbox,
    CircularProgress,
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import MainCard from "../cards/MainCard";
import TableToolbar from "./TableToolbar";
import CustomTableHead from "./CustomTableHead";
import PropTypes from "prop-types";

function descendingComparator(a, b, orderBy) {
    const isDateTime = (str) => {
        return typeof str === 'string' && (
            // Matches DD-MM-YYYY HH:mm:ss
            /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}:\d{2}$/.test(str) ||
            // Matches DD/MM/YYYY
            /^\d{2}\/\d{2}\/\d{4}$/.test(str)
        );
    };

    const parseUKDate = (dateStr) => {
        try {
            // Handle DD-MM-YYYY HH:mm:ss
            if (dateStr.includes('-')) {
                const [datePart, timePart] = dateStr.split(' ');
                const [day, month, year] = datePart.split('-');
                const [hour, minute, second] = timePart.split(':');
                // months are 0-based in JavaScript Date
                return new Date(year, month - 1, day, hour, minute, second);
            }
            // Handle DD/MM/YYYY
            const [day, month, year] = dateStr.split('/');
            return new Date(year, month - 1, day);
        } catch (error) {
            console.error('Error parsing date:', dateStr, error);
            return new Date(0); // Return earliest possible date if parsing fails
        }
    };

    const aValue = a[orderBy];
    const bValue = b[orderBy];

    // Handle date strings
    if (isDateTime(aValue) && isDateTime(bValue)) {
        const dateA = parseUKDate(aValue);
        const dateB = parseUKDate(bValue);
        if (dateA && dateB) {
            return dateB.getTime() - dateA.getTime();
        }
    }

    // Handle regular strings
    if (typeof bValue === "string" && typeof aValue === "string") {
        return bValue.localeCompare(aValue);
    }

    // Handle numbers
    return bValue - aValue;
}

function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedArray.map(el => el[0]);
}

Table.propTypes = {
    name: PropTypes.string.isRequired,
    data: PropTypes.array,
    error: PropTypes.object,
    loading: PropTypes.bool,
    headCells: PropTypes.array.isRequired,
    search: PropTypes.bool,
    editColumn: PropTypes.bool,
    checkbox: PropTypes.bool,
    onCellClick: PropTypes.func,
    toolbarActions: PropTypes.array,
    onDelete: PropTypes.func,
    selected: PropTypes.array,
    setSelected: PropTypes.func,
    showToolbarActions: PropTypes.bool,
    maxTableHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    initialVisibleColumns: PropTypes.array,
    count: PropTypes.number,
    paginationModel: PropTypes.object,
    setPaginationModel: PropTypes.func,
    isServerSidePagination: PropTypes.bool,
    getChangedData: PropTypes.func,
    serverSearchQuery: PropTypes.string,
    setServerSearchQuery: PropTypes.func,
    showDelete: PropTypes.bool,
    enableSorting: PropTypes.bool,
    CSVButton: PropTypes.shape({
        label: PropTypes.string,
        enabled: PropTypes.bool,
        onClick: PropTypes.func
    }),
    disablePagination: PropTypes.bool, // New prop for disabling pagination
    size: PropTypes.string // Prop for table size ('small', 'medium')
};

export default function Table(props) {
    const {
        name,
        data,
        error,
        loading,
        headCells,
        search,
        editColumn,
        checkbox,
        onCellClick,
        toolbarActions,
        onDelete,
        showDelete = true,
        selected,
        setSelected,
        showToolbarActions,
        maxTableHeight,
        initialVisibleColumns = headCells.map((cell) => cell.label),
        count,
        paginationModel,
        setPaginationModel,
        getChangedData,
        isServerSidePagination = false,
        serverSearchQuery,
        setServerSearchQuery,
        CSVButton,
        plural = '',
        enableSorting = true,
        disablePagination = false, // Default to false to maintain backward compatibility
        size = 'small' // Default to small
    } = props;

    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCellNames, setSelectedCellNames] = useState(initialVisibleColumns);
    const [isAdmin, setIsAdmin] = useState(false);
    const [localSearchQuery, setLocalSearchQuery] = useState("");


    useEffect(() => {
        headCells.forEach((cell) => {
            if (initialVisibleColumns.includes(cell.label)) {
                setSelectedCellNames((prev) => [...prev, cell.label]);
            }
        });
    }, [headCells, initialVisibleColumns]);

    useEffect(() => {
        if (isServerSidePagination) {
            setServerSearchQuery(searchQuery);
            setPaginationModel(prev => ({ ...prev, page: 0 }));
        } else {
            setLocalSearchQuery(searchQuery);
            setPaginationModel(prev => ({ ...prev, page: 0 }));
        }
    }, [searchQuery]);



    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user?.role === "Administrators");
    }, []);

    useEffect(() => {
        if (isServerSidePagination && getChangedData) {
            getChangedData(paginationModel);
        }
    }, [paginationModel, isServerSidePagination, getChangedData]);

    const handlePageChange = (event, newPage) => {
        const newPaginationModel = { ...paginationModel, page: newPage };
        setPaginationModel(newPaginationModel);
    };

    const handleRowsPerPageChange = (event) => {
        const newPaginationModel = { ...paginationModel, pageSize: parseInt(event.target.value, 10), page: 0 };
        setPaginationModel(newPaginationModel);
    };

    const handleRequestSort = (event, property) => {
        if (enableSorting) {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        }
    };

    const handleSelectAllClick = (event) => {
        if (selected.length > 0) {
            // If any rows are selected (some or all), deselect all
            setSelected([]);
        } else {
            // If no rows are selected, select all
            const newSelected = data.map((n) => n.id);
            setSelected(newSelected);
        }
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const filteredData = useMemo(() => {
        if (!data || !Array.isArray(data)) return [];
        if (!localSearchQuery) return data;
        return data.filter((row) =>
            headCells.some((cell) => {
                const dataKey = cell.dataKey || cell.id;
                return row && row[dataKey] && row[dataKey].toString().toLowerCase().includes(localSearchQuery.toLowerCase());
            })
        );
    }, [localSearchQuery, data, headCells]);


    const sortedData = useMemo(() => {
        if (!enableSorting || !orderBy || !filteredData) return filteredData || [];
        return stableSort(filteredData, getComparator(order, orderBy));
    }, [filteredData, order, orderBy, enableSorting]);

    const paginatedData = useMemo(() => {
        // Return all data when pagination is disabled
        if (disablePagination) {
            return sortedData || [];
        }

        if (isServerSidePagination) {
            return sortedData || [];
        } else {
            const start = paginationModel.page * paginationModel.pageSize;
            const end = start + paginationModel.pageSize;
            return sortedData?.slice(start, end) || [];
        }
    }, [sortedData, paginationModel.page, paginationModel.pageSize, isServerSidePagination, disablePagination]);

    useEffect(() => {
        setLocalSearchQuery(searchQuery);
        setPaginationModel(prev => ({ ...prev, page: 0 }));
    }, [searchQuery]);


    return (
        <MainCard content={false} sx={{ width: "100%", borderRadius: 1, bgcolor: 'background.default' }} border={true} boxShadow={true}>
            {!(loading && error) &&
                showToolbarActions && toolbarActions && (
                    <TableToolbar
                        numSelected={selected ? selected.length : 0}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        name={plural}
                        cellNames={headCells}
                        selectedCellNames={selectedCellNames}
                        setSelectedCellNames={setSelectedCellNames}
                        showSearch={search}
                        title={name}
                        editColumn={editColumn}
                        toolbarActions={toolbarActions}
                        showToolbarActions={showToolbarActions}
                        onDelete={onDelete}
                        showDelete={showDelete}
                        CSVButton={CSVButton}
                        isAdmin={isAdmin}
                    />
                )
            }
            <TableContainer sx={{ maxHeight: maxTableHeight }}>
                <MuiTable
                    stickyHeader
                    sx={{ minWidth: 900, maxWidth: "100%" }}
                    aria-labelledby="tableTitle"
                    size={size}
                >
                    <CustomTableHead
                        numSelected={selected ? selected.length : 0}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={filteredData && filteredData.length ? filteredData.length : 0}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        cells={headCells.filter((cell) => selectedCellNames.includes(cell.label))}
                        checkbox={checkbox}
                        enableSorting={enableSorting}
                    />
                    <TableBody>
                        {loading ? (
                            <TableRow style={{ height: 400 }}>
                                <TableCell colSpan={headCells.length}>
                                    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                                        <CircularProgress color="inherit" size={24} />
                                        <Typography variant="h6" align="center">
                                            Loading {plural || name}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : error ? (
                            <TableRow style={{ height: 400 }}>
                                <TableCell colSpan={headCells.length}>
                                    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                                        <ErrorIcon color="error" />
                                        <Typography variant="error" align="center">
                                            Error fetching {plural || name}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : paginatedData && paginatedData.length > 0 ? (
                            paginatedData.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover={false}
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        {checkbox && (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row.id)}
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": labelId
                                                    }}
                                                />
                                            </TableCell>
                                        )}
                                        {headCells.filter((cell) => selectedCellNames.includes(cell.label)).map((cell, i) => {
                                            const dataKey = cell.dataKey || cell.id;
                                            const cellValue = cell.render ? cell.render(row) : row[dataKey];
                                            const onClick = cell.clickable ? (onCellClick ? () => onCellClick(row) : null) : null;
                                            return (
                                                <TableCell
                                                    key={cell.id}
                                                    onClick={onClick}
                                                    component={i === 0 ? "th" : undefined}
                                                    sx={{ pl: (i === 0 ? 2 : 1.5) }}
                                                    scope={i === 0 ? "row" : undefined}
                                                >
                                                    {cellValue || "-"}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <TableRow style={{ height: 400 }}>
                                <TableCell colSpan={headCells.length}>
                                    <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
                                        <Typography variant="h6" align="center">
                                            {plural ? "No " + plural + " found" : "None found"}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </MuiTable>
            </TableContainer>

            {/* Only render pagination if not disabled */}
            {!disablePagination && (
                <TablePagination
                    shape="rounded"
                    backIconButtonText="Previous page"
                    nextIconButtonText="Next page"
                    component="div"
                    count={count || (filteredData && filteredData.length) || 0}
                    rowsPerPage={paginationModel.pageSize}
                    page={paginationModel.page}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
                />
            )}
        </MainCard>
    );
}