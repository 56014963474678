import { journeyApi } from "../services/journey-service";
import { onDemandApi } from "../services/ondemand-service";
import { quicksightApi } from "../services/quicksight-service";
import { responseApi } from "../services/response.service";
import { deliveryApi } from "../services/delivery-service";
import { schedulerApi } from "../services/scheduler.service";
import { simulationApi } from "../services/simulation-service";
import { tenancyApi } from "../services/tenancy.service";
import { tenancyReportingApi } from "../services/tenancy-reporting.service.js";
import { universityApi } from "../services/university-service";
import { userApi } from "../services/user-service";
import { reportApi } from "../services/report.service";

const api = {
    // Add the generated reducer as a specific top-level slice
    [journeyApi.reducerPath]: journeyApi.reducer,
    [onDemandApi.reducerPath]: onDemandApi.reducer,
    [quicksightApi.reducerPath]: quicksightApi.reducer,
    [responseApi.reducerPath]: responseApi.reducer,
    [deliveryApi.reducerPath]: deliveryApi.reducer,
    [schedulerApi.reducerPath]: schedulerApi.reducer,
    [simulationApi.reducerPath]: simulationApi.reducer,
    [tenancyApi.reducerPath]: tenancyApi.reducer,
    [tenancyReportingApi.reducerPath]: tenancyReportingApi.reducer,
    [universityApi.reducerPath]: universityApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
};

export default api;