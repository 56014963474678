import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import { useGetJourneyByIdQuery, useGetJourneysQuery } from "../../store/services/journey-service";
import parseJourneyDataMessage from "../../utils/ParseJourneyDataMessage";
import { useUpdateTenancyMutation } from "../../store/services/tenancy.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import { ReactComponent as SimulationIcon } from "assets/navigation-icons/simulation.svg";
import { ReactComponent as CourseIcon } from "assets/navigation-icons/course.svg";
import { timelineItemClasses } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import MainCard from "../../components/cards/MainCard";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import JourneySkeletonComponent from "../../components/Skeleton/JourneySkeletonComponent";
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../utils/colorUtils";

Journeys.propTypes = {
    data: PropTypes.object.isRequired
};

export default function Journeys(props) {
    const { data } = props;
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const { data: journeyData, isLoading } = useGetJourneyByIdQuery(
        { journeyId: data.journeyId },
        { skip: !data.journeyId } 
    );

    const { data: journeysData, isLoading: journeysLoading, error: journeysError } = useGetJourneysQuery({
        perPage: 1000,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    const [updateTenancy] = useUpdateTenancyMutation();

    const [tenancyData, setTenancyData] = useState(data);
    const [dateString, setDateString] = useState(data.startUserJourneyOn || new Date().toISOString());

    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    function handleSave(tenancyData) {
        return () => {
            updateTenancy({
                data: { ...tenancyData, startUserJourneyOn: new Date(dateString) },
                tenancyId: data.tenancyId
            }).unwrap()
                .then(() => {
                    setSnackbarSeverity("success");
                    setSnackbarMessage("Journey updated");
                    setSnackbarOpen(true);
                })
                .catch((error) => {
                    console.error("Error updating tenancy", error);
                    setSnackbarSeverity("error");
                    setSnackbarMessage("Error updating tenancy");
                    setSnackbarOpen(true);
                });
        };
    }

    if (isLoading || journeysLoading) {
        return <JourneySkeletonComponent />;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <Typography variant="h4">
                            {tenancyData.name} (ID: {data.tenancyId})
                            {data.journeyId ? (
                                <> currently subscribed to&nbsp;
                                    <a href={`../journeys/${data.journeyId}`} alt={"Journey link"}>{data.journeyName} </a>
                                </>
                            ) : (
                                " has no journey assigned"
                            )}
                        </Typography>

                        {journeyData && journeyData.journeyOptions && (
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0
                                    }
                                }}
                            >
                                {[...journeyData.journeyOptions]
                                    .sort((a, b) => {
                                        const monthA = Number(a.month);
                                        const monthB = Number(b.month);
                                        const dayA = Number(a.day);
                                        const dayB = Number(b.day);

                                        if (monthA !== monthB) {
                                            return monthA - monthB;
                                        }

                                        if (dayA !== dayB) {
                                            return dayA - dayB;
                                        }

                                        return new Date(a.dateCreated || 0) - new Date(b.dateCreated || 0);
                                    })
                                    .map((option, index) => {
                                        const { message, isSent } = parseJourneyDataMessage(option, new Date(data.startUserJourneyOn));

                                    return (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot
                                                    color={(option.type === 1 ? "primary" : "secondary")}
                                                    variant={isSent ? "filled" : "outlined"}
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        width: "40px",
                                                        height: "40px"
                                                    }}
                                                >
                                                    {option.type === 1 ? <CourseIcon width="20px" height="20px" /> :
                                                        <SimulationIcon width="20px" height="20px" />}
                                                </TimelineDot>
                                                {index < journeyData.journeyOptions.length - 1 && <TimelineConnector />}
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ py: "12px", px: 2 }}>
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                    <Box sx={{ flexGrow: 1 }}>
                                                        <Typography variant="h6">
                                                            {option.type === 0 ? "Simulation" : "Course"}: {option.typeName || option.typeId}
                                                        </Typography>
                                                        <Typography variant="caption">
                                                            {message}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </TimelineContent>
                                        </TimelineItem>
                                    );
                                })}
                            </Timeline>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={8}>
                    <MainCard content={true} boxShadow={false} border={false} sx={{ bgcolor: getCardColor(lightMode) }}>
                        <Typography variant="h5" gutterBottom>
                            Select a journey for {tenancyData.name}
                        </Typography>

                        {!journeysError && (
                            <Box sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                width: "100%",
                                mt: 4,
                                gap: 2
                            }}>
                                <FormControl fullWidth>
                                    <InputLabel style={{ backgroundColor: "transparent" }}>Select a journey</InputLabel>
                                    <Select
                                        sx={{ width: "80%", mb: 3, bgcolor: "background.default" }}
                                        label="Select a journey"
                                        variant="outlined"
                                        value={tenancyData.journeyId || ""}
                                        onChange={(e) => {
                                            const selectedJourney = journeysData.find(journey => journey.id === e.target.value);
                                            setTenancyData({
                                                ...tenancyData,
                                                journeyId: e.target.value,
                                                journeyName: selectedJourney?.name || ""
                                            });
                                        }}
                                        MenuProps={{ sx: { color: "background.default" } }}
                                    >
                                        {journeysData && journeysData.map((journey, index) => (
                                            <MenuItem key={index} value={journey.id}>{journey.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Journey Start Date"
                                value={new Date(dateString)}
                                onChange={(e) => {
                                    setDateString(new Date(e).toISOString());
                                }}
                                sx={{ width: "80%", "& .MuiInputLabel-root": { backgroundColor: "transparent" } }}
                                format="dd MMMM yyyy"
                            />
                        </LocalizationProvider>
                    </MainCard>
                </Grid>

                <Grid item xs={12} mb={2}>
                    <Button variant="outlined" color="black" onClick={() => navigate(location.from ? location.from : -1)}
                        sx={{ width: "10%" }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSave(tenancyData)} variant="contained" color="warning" sx={{ ml: 2, width: "10%" }}>
                        Save
                    </Button>
                </Grid>
            </Grid>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackbarOpen} autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled" sx={{ width: "100%" }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
}