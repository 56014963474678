// store/index.js

// Third-party imports
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// Project imports
import reducers from './reducers';
import { journeyApi } from "./services/journey-service";
import { onDemandApi } from "./services/ondemand-service";
import { quicksightApi } from "./services/quicksight-service";
import { responseApi } from "./services/response.service";
import { schedulerApi } from "./services/scheduler.service";
import { simulationApi } from "./services/simulation-service";
import { tenancyApi } from "./services/tenancy.service";
import { tenancyReportingApi } from "./services/tenancy-reporting.service.js";
import { universityApi } from "./services/university-service";
import { userApi } from "./services/user-service";
import { reportApi } from "./services/report.service";
import { deliveryApi } from "./services/delivery-service";

// Combine RTK Query middleware for easier extension
const rtkQueryMiddleware = [
    journeyApi.middleware,
    onDemandApi.middleware,
    quicksightApi.middleware,
    responseApi.middleware,
    schedulerApi.middleware,
    simulationApi.middleware,
    tenancyApi.middleware,
    tenancyReportingApi.middleware,
    universityApi.middleware,
    userApi.middleware,
    reportApi.middleware,
    deliveryApi.middleware,
];

const rootReducer = combineReducers(reducers);

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['tenancy'], // Only persist the tenancy slice
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(rtkQueryMiddleware),
});

export const persistor = persistStore(store);

const { dispatch } = store;

export { store, dispatch };
