import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Divider,
    Chip,
    Grid,
    Card,
    CardContent,
    Button,
    IconButton,
    CircularProgress
} from '@mui/material';
import {
    ArrowBack as BackIcon,
    Edit as EditIcon
} from '@mui/icons-material';
import MainCard from "../../components/cards/MainCard";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import { useGetDeliveryRequestByIdQuery } from '../../store/services/delivery-service';

const DeliveryRequestDetail = () => {
    const { tenancyId, requestId } = useParams();
    const navigate = useNavigate();

    const {
        data: deliveryData,
        isLoading,
        error
    } = useGetDeliveryRequestByIdQuery({ tenancyId, requestId });

    const handleEdit = () => {
        navigate(`/delivery/edit/${tenancyId}/${requestId}`);
    };

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Paper elevation={3} sx={{ p: 3 }}>
                <Typography color="error" variant="h6">
                    Error: {error.status} - {error.data?.message || 'Failed to load journey details'}
                </Typography>
                <Button
                    variant="contained"
                    startIcon={<BackIcon />}
                    onClick={() => navigate('/delivery')}
                    sx={{ mt: 2 }}
                >
                    Back
                </Button>
            </Paper>
        );
    }

    const request = deliveryData?.request;
    // Sort items by scheduledFor date in ascending order
    const items = [...(deliveryData?.items || [])].sort((a, b) => {
        return new Date(a.scheduledFor) - new Date(b.scheduledFor);
    });

    const statusColor = (status) => {
        switch (status) {
            case 'Pending': return 'warning';
            case 'InProgress': return 'secondary';
            case 'Completed': return 'primary';
            case 'Cancelled': return 'error';
            default: return 'default';
        }
    };

    const itemStatusColor = (status) => {
        switch (status) {
            case 'Pending': return 'warning';
            case 'Sent': return 'primary';
            case 'Failed': return 'error';
            case 'Cancelled': return 'error';
            default: return 'default';
        }
    };

    return (
        <MainCard
            content={true}
            boxShadow={true}
            border={false}
            sx={{ bgcolor: 'background.default' }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <Button
                    onClick={() => navigate('/delivery')}
                    variant="contained"
                    startIcon={<KeyboardArrowLeftTwoToneIcon />}
                >
                    Back
                </Button>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Journey Name
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {request?.name}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Status
                    </Typography>
                    <Chip
                        label={request?.status}
                        color={statusColor(request?.status)}
                        size="small"
                    />
                </Grid>

                {/*empty grid item for spacing*/}
                <Grid item xs={12} md={4}>

                </Grid>

                { /*
                <Grid item xs={12} md={3}>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Type
                    </Typography>
                    <Typography variant="body1">
                        {request?.type}
                    </Typography>
                </Grid>
                */ }
                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Created At
                    </Typography>
                    <Typography variant="body1">
                        {new Date(request?.createdAt).toLocaleString()}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Last Updated
                    </Typography>
                    <Typography variant="body1">
                        {new Date(request?.updatedAt).toLocaleString()}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Typography variant="subtitle1" component="div" gutterBottom>
                        Created By
                    </Typography>
                    <Typography variant="body1">
                        {request?.createdById || 'N/A'}
                    </Typography>
                </Grid>
            </Grid>

            <Divider sx={{ my: 4 }} />

            <Typography variant="h6" gutterBottom>
                Items ({items.length})
            </Typography>

            {items.length === 0 ? (
                <Typography>No items in this journey.</Typography>
            ) : (
                <>
                    <Box sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                            {items.map((item, index) => (
                                <Grid item xs={12} md={6} key={item.id || index}>
                                    <MainCard
                                        content={true}
                                        boxShadow={true}
                                        border={false}
                                        sx={{ bgcolor: 'background.default' }}
                                        title={

                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography variant="subtitle1" component="div">
                                                        Item {index + 1}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                                    <Chip
                                                        label={item.status}
                                                        color={itemStatusColor(item.status)}
                                                        size="small"
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    >
                                        <Grid container spacing={2}>

                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" component="div">
                                                    Content Name: {item.contentId}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    Content Type: {item.contentType}
                                                </Typography>
                                            </Grid>
                                            { /*
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="subtitle2" component="div">
                                                    Recipient Type:
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {item.recipientType}
                                                </Typography>
                                            </Grid>
                                                
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="subtitle2" component="div">
                                                    Send To ID:
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {item.sendToId}
                                                </Typography>
                                            </Grid>
                                            */}

                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="subtitle2" component="div">
                                                    Scheduled For:
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {new Date(item.scheduledFor).toLocaleString()}
                                                </Typography>
                                            </Grid>
                                            {/*
                                                <Grid item xs={12} sm={6}>
                                                    <Typography variant="subtitle2" component="div">
                                                        Sent At:
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {item.sentAt ? new Date(item.sentAt).toLocaleString() : 'Not sent yet'}
                                                    </Typography>
                                                </Grid>
                                            */}
                                            {/*item.attempts > 0 && (
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" component="div">
                                                        Attempts: {item.attempts}
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Last Attempt: {item.lastAttemptAt ? new Date(item.lastAttemptAt).toLocaleString() : 'N/A'}
                                                    </Typography>
                                                </Grid>
                                            )*/}

                                            { /*item.error && (
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle2" component="div" color="error">
                                                        Error:
                                                    </Typography>
                                                    <Typography variant="body2" color="error">
                                                        {item.error}
                                                    </Typography>
                                                </Grid>
                                            ) */}
                                        </Grid>
                                    </MainCard>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </>
            )}
        </MainCard>
    );
};

export default DeliveryRequestDetail;