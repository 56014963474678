import MainLayout from "layout/MainLayout";
import Journeys from "../pages/journeys";
import Courses from "../pages/courses";
import Simulations from "../pages/simulations";
import CourseDetails from "../pages/courses/CourseDetails";
import SimulationDetails from "../pages/simulations/SimulationDetails";
import JourneyDetails from "../pages/journeys/JourneyDetails";
import DeliveryDashboard from "../pages/delivery/index";
import DeliveryRequestForm from "../pages/delivery/DeliveryRequestForm";
import DeliveryRequestDetail from "../pages/delivery/DeliveryRequestDetail";
import PresetDeliveryForm from "../pages/delivery/PresetDeliveryForm";
import DeliveryCalendarPage from "../pages/delivery/DeliveryCalendarPage";


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: "journeys",
            element: <Journeys />
        },
        {
            path: "journeys/:id",
            element: <JourneyDetails />
        },
        {
            path: "journeys/add",
            element: <JourneyDetails />
        },
        {
            path: "courses",
            element: <Courses />
        },
        {
            path: "courses/:id",
            element: <CourseDetails />
        },
        {
            path: "courses/add",
            element: <CourseDetails />
        },
        {
            path: "simulations",
            element: <Simulations />
        },
        {
            path: "simulations/:id",
            element: <SimulationDetails />
        },
        {
            path: "simulations/add",
            element: <SimulationDetails />
        },
        {
            path: "simulations/edit/:id",
            element: <SimulationDetails />
        },
        {
            path: "delivery",
            element: <DeliveryDashboard />
        },
        {
            path: "delivery/edit/:tenancyId/:requestId",
            element: <DeliveryRequestForm isEditing={true} />
        },
        {
            path: "delivery/view/:tenancyId/:requestId",
            element: <DeliveryRequestDetail />
        },
        {
            path: "delivery/preset/create",
            element: <PresetDeliveryForm />
        },
        {
            path: "delivery/preset/edit/:presetId",
            element: <PresetDeliveryForm isEditing={true} />
        },
        {
            path: "delivery/calendar/:tenancyId/:deliveryGroupId",
            element: <DeliveryCalendarPage />
        },
        {
            path: "delivery/calendar-create/:tenancyId",
            element: <DeliveryCalendarPage />
        },
        {
            path: "delivery/calendar-preset/:tenancyId",
            element: <DeliveryCalendarPage />
        },
    ]
};

export default MainRoutes;
