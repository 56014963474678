import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    IconButton,
    Typography,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    InputAdornment,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    FilterAlt as FilterIcon,
    Visibility as ViewIcon,
    CalendarToday as CalendarTodayIcon,
    CalendarMonth as CalendarIcon,
    Login as LoginIcon
} from '@mui/icons-material';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { format } from 'date-fns';
import Table from '../../components/Table/Table';
import UsersTable from '../users/UsersTable';
import ConfirmationDialog from "../../utils/confirmationDialog";

import {
    useGetDeliveryRequestsQuery,
    useDeleteDeliveryRequestMutation,
    useAssignPresetRequestMutation
} from '../../store/services/delivery-service';
import { useGetPresetRequestsQuery } from '../../store/services/delivery-service';

const DeliveryRequestTable = ({ tenancyId, showNotification, onViewCalendar }) => {
    const navigate = useNavigate();

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    const [filters, setFilters] = useState({
        statusFilter: '',
        typeFilter: '',
        dateRangeStart: null,
        dateRangeEnd: null
    });

    const [filtersOpen, setFiltersOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [selectedPresetId, setSelectedPresetId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [minStartDate, setMinStartDate] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    // Refs for flatpickr
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);
    const startDatePickerRef = useRef(null);
    const endDatePickerRef = useRef(null);

    // Set minimum start date when component mounts
    useEffect(() => {
        // Set minimum date to tomorrow
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setMinStartDate(tomorrow.toISOString().split('T')[0]);
    }, []);


    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);

    const isStartDateValid = startDate && new Date(startDate) > new Date(new Date().setHours(0, 0, 0, 0));

    // Initialize flatpickr
    useEffect(() => {
        // Date picker options
        const dateConfig = {
            enableTime: false,
            dateFormat: "Y-m-d",
            allowInput: true,
            onChange: ([date], dateStr, instance) => {
                if (date) {
                    const filterName = instance === startDatePickerRef.current
                        ? 'dateRangeStart'
                        : 'dateRangeEnd';
                    handleDateChange(filterName, date);
                }
            }
        };

        // Clean up previous instances
        if (startDatePickerRef.current) {
            startDatePickerRef.current.destroy();
        }
        if (endDatePickerRef.current) {
            endDatePickerRef.current.destroy();
        }

        // Initialize start date picker
        if (startDateRef.current) {
            startDatePickerRef.current = flatpickr(startDateRef.current, {
                ...dateConfig,
                defaultDate: filters.dateRangeStart
            });
        }

        // Initialize end date picker
        if (endDateRef.current) {
            endDatePickerRef.current = flatpickr(endDateRef.current, {
                ...dateConfig,
                defaultDate: filters.dateRangeEnd
            });
        }

        return () => {
            // Clean up flatpickr instances on unmount
            if (startDatePickerRef.current) {
                startDatePickerRef.current.destroy();
            }
            if (endDatePickerRef.current) {
                endDatePickerRef.current.destroy();
            }
        };
    }, [filtersOpen]);

    // Format dates for display
    const formatDate = (date) => {
        return date ? format(date, 'yyyy-MM-dd') : '';
    };

    // Query to get delivery requests
    const {
        data: deliveryData,
        isLoading,
        error,
        refetch
    } = useGetDeliveryRequestsQuery({
        tenancyId,
        perPage: paginationModel.pageSize,
        page: paginationModel.page + 1,
        ...filters
    });

    // Create filtered data by removing cancelled requests
    const filteredData = React.useMemo(() => {
        if (!deliveryData) return null;

        // Filter out requests with "Cancelled" status
        const filteredRequests = deliveryData.requests.filter(req => req.status !== "Cancelled");

        // Return a new object with the filtered requests
        return {
            ...deliveryData,
            requests: filteredRequests,
            // Adjust total records count (for pagination)
            totalRecords: deliveryData.totalRecords - (deliveryData.requests.length - filteredRequests.length)
        };
    }, [deliveryData]);

    // Get presets for assignment
    const { data: presetData } = useGetPresetRequestsQuery({
        perPage: 100,
        page: 1
    });

    // Mutations
    const [deleteDeliveryRequest, { isLoading: isDeleting }] = useDeleteDeliveryRequestMutation();
    const [assignPresetRequest, { isLoading: isAssigning }] = useAssignPresetRequestMutation();

    // New function for calendar-based creation
    const handleCreateWithCalendar = () => {
        navigate(`/delivery/calendar-create/${tenancyId}`);
    };


    // Handle editing a delivery request
    const handleEditDeliveryRequest = (request) => {
        navigate(`/delivery/edit/${tenancyId}/${request.id}`);
    };

    // Handle deleting a delivery request
    const handleDeleteDeliveryRequest = (requestId) => {
        setSelectedRequestId(requestId);
        setDialogTitle('Cancel Delivery Request');
        setDialogContent('Are you sure you want to cancel this delivery request? This action cannot be undone.');
        setDialogAction(() => async () => {
            try {
                await deleteDeliveryRequest({ tenancyId, requestId }).unwrap();
                showNotification('Delivery request cancelled successfully', 'success');
            } catch (err) {
                showNotification('Failed to cancel delivery request: ' + (err.data?.message || 'Unknown error'), 'error');
            }
        });
        setConfirmDialogOpen(true);
    };

    // Handle viewing a delivery request
    const handleViewDeliveryRequest = (request) => {
        navigate(`/delivery/view/${tenancyId}/${request.id}`);
    };

    // Handle viewing calendar for a delivery request
    const handleViewCalendar = (request) => {
        console.log("Navigating to calendar for request:", request.id);
        navigate(`/delivery/calendar/${tenancyId}/${request.id}`);
    };

    // Handle filter changes
    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setFilters(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle date filter changes
    const handleDateChange = (name, date) => {
        setFilters(prev => ({
            ...prev,
            [name]: date ? format(date, 'yyyy-MM-dd') : null
        }));
    };

    // Reset filters
    const handleResetFilters = () => {
        setFilters({
            statusFilter: '',
            typeFilter: '',
            dateRangeStart: null,
            dateRangeEnd: null
        });

        // Reset flatpickr instances
        if (startDatePickerRef.current) {
            startDatePickerRef.current.clear();
        }
        if (endDatePickerRef.current) {
            endDatePickerRef.current.clear();
        }
    };

    // Open assign preset modal
    const handleOpenAssignModal = () => {
        setAssignModalOpen(true);
    };

    // Handle assign preset submission
    const handleAssignPreset = async () => {
        if (!selectedPresetId) {
            showNotification('Please select a preset template', 'error');
            return;
        }

        try {
            const assignData = {
                name: `Preset Delivery - ${new Date().toISOString().split('T')[0]}`,
                startDate: new Date().toISOString(),
                recipientType: 'Tenancy',
                sendToId: tenancyId.toString()
            };

            await assignPresetRequest({
                tenancyId,
                presetId: selectedPresetId,
                assignData
            }).unwrap();

            showNotification('Preset template assigned successfully', 'success');
            setAssignModalOpen(false);
            setSelectedPresetId('');
        } catch (err) {
            showNotification('Failed to assign preset template: ' + (err.data?.message || 'Unknown error'), 'error');
        }
    };

    // Replace the handleAssignPreset function with this new function
    const handleOpenCalendarWithPreset = () => {
        if (!selectedPresetId || !isStartDateValid) {
            showNotification('Please select a template and valid start date', 'error');
            return;
        }

        // Find the selected preset
        const selectedPreset = presetData?.presets?.find(preset => preset.id === selectedPresetId);

        if (!selectedPreset) {
            showNotification('Selected template not found', 'error');
            return;
        }

        // Navigate to calendar view with preset data
        navigate(`/delivery/calendar-preset/${tenancyId}`, {
            state: {
                preset: selectedPreset,
                startDate: startDate
            }
        });

        // Close the modal
        setAssignModalOpen(false);
        setSelectedPresetId('');
        setStartDate('');
    };



    const statusChipColor = (status) => {
        switch (status) {
            case 'Pending': return 'warning';
            case 'InProgress': return 'info';
            case 'Completed': return 'success';
            case 'Cancelled': return 'error';
            default: return 'default';
        }
    };

    // Table column definitions
    const headCells = [
        /*
        {
            id: "preview",
            label: "",
            render: (dataRow) => (
                <>
                    <Button
                        //                        onClick={() => handlePreview(dataRow)} 
                        size="small" variant="contained" color="warning">
                        Preview
                    </Button>
                </>
            ),
            initialVisible: true
        },
        {
            id: "edit",
            label: "",
            render: (dataRow) => {
                if (dataRow.tenancyId === 0 && !isAdmin) return <></>;

                return (
                    <Button
                        //onClick={() => handleEditContentPack(dataRow)}
                        size="small"
                        variant="contained"
                        color="secondary"
                    >
                        Edit
                    </Button>
                );
            },
            initialVisible: true
        },
        */
        // Calendar view button
        {
            id: "calendar",
            label: "",
            render: (dataRow) => (
                <Button
                    onClick={() => handleViewCalendar(dataRow)}
                    size="small"
                    variant="contained"
                    color="secondary"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    Edit
                </Button>
            ),
            initialVisible: true
        },
        {
            id: "edit",
            label: "",
            render: (dataRow) => {
                return (
                    <Button
                        onClick={() => handleViewDeliveryRequest(dataRow)}
                        size="small"
                        variant="contained"
                        color="warning"
                    >
                        Overview
                    </Button>
                );
            },
            initialVisible: true
        },
        {
            id: 'name',
            label: 'Name',
            initialVisible: true
        },
        {
            id: 'type',
            label: 'Type',
            initialVisible: true,
            render: (row) => {
                if (row.type == "Template") return "Journey";
            }
        },
        {
            id: 'items',
            label: 'Items',
            initialVisible: true,
            render: (row) => {
                const items = filteredData?.items?.[row.id] || [];
                return items.length;
            }
        },

        {
            id: 'createdAt',
            label: 'Created ',
            initialVisible: true,
            render: (row) => new Date(row.createdAt).toLocaleString()
        },
        {
            id: 'updatedAt',
            label: 'Last Updated',
            initialVisible: true,
            render: (row) => new Date(row.updatedAt).toLocaleString()
        },
        {
            id: "action",
            label: "",
            render: (dataRow) => {
                // Only show delete button to admin users
                if (!isAdmin) return <></>;

                return (
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteDeliveryRequest(dataRow.id)}
                        disabled={isDeleting && selectedRequestId === dataRow.id}
                        sx={{
                            minWidth: '70px',
                            width: '70px',
                            height: '32px',
                            padding: '6px 8px',
                            '& .MuiCircularProgress-root': {
                                marginRight: 0
                            }
                        }}
                    >
                        {isDeleting && selectedRequestId === dataRow.id ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            "Cancel"
                        )}
                    </Button>
                );
            },
            initialVisible: true
        }
        /*
        {
            id: "action",
            label: "",
            render: (dataRow) => {
                return (
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => handleDeleteDeliveryRequest(dataRow.id)}
                        disabled={isDeleting && selectedRequestId === dataRow.id}
                        sx={{
                            minWidth: '70px',
                            width: '70px',
                            height: '32px',
                            padding: '6px 8px',
                            '& .MuiCircularProgress-root': {
                                marginRight: 0
                            }
                        }}
                    >

                        {isDeleting && selectedRequestId === dataRow.id ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : (
                            "Delete"
                        )}
                    </Button>
                );
            },
            initialVisible: true
        }
        */
    ];

    // Toolbar actions
    const toolbarActions = [
        {
            render: () => (
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<AddIcon />}
                        onClick={handleCreateWithCalendar}
                    >
                        Create Custom
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<CalendarIcon />}
                        onClick={handleOpenAssignModal}
                    >
                        Use Template
                    </Button>
                </Box>
            )
        }
    ];

    return (
        <>

            <Table
                name="Delivery Requests"
                data={filteredData?.requests || []}
                error={error}
                loading={isLoading}
                headCells={headCells}
                search={true}
                selected={[]}
                setSelected={() => { }}
                checkbox={false}
                toolbarActions={toolbarActions}
                showToolbarActions={true}
                maxTableHeight="calc(100vh - 360px)"
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowCount={filteredData?.totalRecords || 0}
            />

            {/* Assign Preset Modal */}
            <Dialog open={assignModalOpen} onClose={() => setAssignModalOpen(false)}>
                <DialogTitle sx={{ bgcolor: 'background.default' }} >Select Journey Template</DialogTitle>
                <DialogContent sx={{ minWidth: 450, bgcolor: 'background.default' }}

                >
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Select Template</InputLabel>
                                <Select
                                    value={selectedPresetId}
                                    label="Select Template"
                                    onChange={(e) => setSelectedPresetId(e.target.value)}
                                >
                                    {presetData?.presets?.map((preset) => (
                                        <MenuItem key={preset.id} value={preset.id}>
                                            {preset.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Start Date"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    min: minStartDate,
                                }}
                                error={!!startDate && !isStartDateValid}
                                helperText={startDate && !isStartDateValid ? "Please select a future date (starting from tomorrow)" : ""}
                            />
                        </Grid>
                        {selectedPresetId && presetData?.presets && (
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    This template contains {presetData.presets.find(p => p.id === selectedPresetId)?.items?.length || 0} items
                                    that will be scheduled based on the start date you select.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ bgcolor: 'background.default' }}>
                    <Button variant="contained" color="secondary" onClick={() => setAssignModalOpen(false)}>Cancel</Button>
                    <Button
                        onClick={handleOpenCalendarWithPreset}
                        variant="contained"
                        disabled={!selectedPresetId || !isStartDateValid}
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmDialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setConfirmDialogOpen(false);
                    dialogAction?.();
                }}
                onCancel={() => setConfirmDialogOpen(false)}
            />
        </>
    );
};

DeliveryRequestTable.propTypes = {
    tenancyId: PropTypes.number.isRequired,
    showNotification: PropTypes.func.isRequired,
    onViewCalendar: PropTypes.func
};

export default DeliveryRequestTable;