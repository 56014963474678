import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Tab,
    Tabs,
    Typography,
    Paper,
    Snackbar,
    Alert,
    Button
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DeliveryRequestTable from './DeliveryRequestTable';
import PresetDeliveryTable from './PresetDeliveryTable';
import MainCard from "../../components/cards/MainCard";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`delivery-tabpanel-${index}`}
            aria-labelledby={`delivery-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default function DeliveryDashboard() {
    const [tabValue, setTabValue] = useState(0);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [selectedDeliveryGroup, setSelectedDeliveryGroup] = useState(null);

    const { tenancyId } = useSelector((state) => state.tenancy);
    const { role } = useSelector((state) => state.auth.user || {});
    const navigate = useNavigate();

    const isAdmin = role === "Administrators";

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const showNotification = (message, severity = 'success') => {
        setSnackbar({
            open: true,
            message,
            severity
        });
    };

    const handleOpenCalendar = (deliveryGroup) => {
        setSelectedDeliveryGroup(deliveryGroup);
        navigate(`/delivery/calendar/${tenancyId}/${deliveryGroup.id}`);
    };

    return (
        <>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="journey tabs">
                        <Tab label="Active" />
                        {isAdmin && <Tab label="Templates" />}
                    </Tabs>
                </Box>

                <TabPanel value={tabValue} index={0}>
                    <DeliveryRequestTable
                        tenancyId={tenancyId}
                        showNotification={showNotification}
                        onViewCalendar={handleOpenCalendar}
                    />
                </TabPanel>

                {isAdmin && (
                    <TabPanel value={tabValue} index={1}>
                        <PresetDeliveryTable
                            showNotification={showNotification}
                            onViewCalendar={handleOpenCalendar}
                        />
                    </TabPanel>
                )}

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={snackbar.severity}
                        sx={{ width: '100%' }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
        </>
    );
}