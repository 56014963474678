import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    IconButton,
    Chip,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    TextField,
    InputAdornment
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Visibility as ViewIcon,
    CalendarToday as CalendarTodayIcon
} from '@mui/icons-material';
import Table from '../../components/Table/Table';
import ConfirmationDialog from "../../utils/confirmationDialog";

import {
    useGetPresetRequestsQuery,
    useDeletePresetRequestMutation
} from '../../store/services/delivery-service';
import MainCard from "../../components/cards/MainCard";


const PresetDeliveryTable = ({ showNotification }) => {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0
    });

    // New state for items table pagination in the preview modal
    const [itemsPaginationModel, setItemsPaginationModel] = useState({
        pageSize: 5,
        page: 0
    });

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogContent, setDialogContent] = useState('');
    const [selectedPresetId, setSelectedPresetId] = useState(null);
    const [presetDetailOpen, setPresetDetailOpen] = useState(false);
    const [selectedPreset, setSelectedPreset] = useState(null);

    // Add state for the start date
    const [startDate, setStartDate] = useState('');
    const [minStartDate, setMinStartDate] = useState('');

    const navigate = useNavigate();

    // Set minimum start date when component mounts
    useEffect(() => {
        // Set minimum date to tomorrow
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        setMinStartDate(tomorrow.toISOString().split('T')[0]);
    }, []);

    // Validate if start date is valid (must be in the future)
    const isStartDateValid = startDate && new Date(startDate) > new Date(new Date().setHours(0, 0, 0, 0));

    // Query to get preset delivery templates
    const {
        data: presetData,
        isLoading,
        error
    } = useGetPresetRequestsQuery({
        perPage: paginationModel.pageSize,
        page: paginationModel.page + 1
    });

    // Delete mutation
    const [deletePresetRequest, { isLoading: isDeleting }] = useDeletePresetRequestMutation();

    // Handle adding new preset template
    const handleAddPreset = () => {
        navigate('/delivery/preset/create');
    };

    // Handle editing a preset template
    const handleEditPreset = (presetId) => {
        navigate(`/delivery/preset/edit/${presetId}`);
    };

    // Handle deleting a preset template
    const handleDeletePreset = (presetId) => {
        setSelectedPresetId(presetId);
        setDialogTitle('Delete Preset Template');
        setDialogContent('Are you sure you want to delete this preset template? This action cannot be undone.');
        setDialogAction(() => async () => {
            try {
                await deletePresetRequest(presetId).unwrap();
                showNotification('Preset template deleted successfully', 'success');
            } catch (err) {
                showNotification('Failed to delete preset template: ' + (err.data?.message || 'Unknown error'), 'error');
            }
        });
        setConfirmDialogOpen(true);
    };

    // Handle viewing preset details
    const handleViewPreset = (preset) => {
        setSelectedPreset(preset);
        setPresetDetailOpen(true);
        // Reset the items pagination when opening the preview modal
        setItemsPaginationModel({
            pageSize: 5,
            page: 0
        });
        // Reset start date when opening the preview
        setStartDate('');
    };

    // Calculate a date by adding days to the start date
    const addDays = (date, days) => {
        if (!date) return null;
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    // Format date as string
    const formatDate = (date) => {
        if (!date) return "";
        return date.toLocaleDateString();
    };

    // Table column definitions
    const headCells = [
        {
            id: "preview",
            label: "",
            render: (dataRow) => (
                <Button
                    onClick={() => handleViewPreset(dataRow)}
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{ whiteSpace: "nowrap" }}
                >
                    Preview
                </Button>
            ),
            initialVisible: true
        },
        {
            id: 'name',
            label: 'Name',
            initialVisible: true
        },
        {
            id: 'itemCount',
            label: 'Items',
            initialVisible: true,
            render: (row) => row.items?.length || 0
        },
        {
            id: 'createdAt',
            label: 'Created At',
            initialVisible: true,
            render: (row) => new Date(row.createdAt).toLocaleString()
        },
        {
            id: 'updatedAt',
            label: 'Updated At',
            initialVisible: true,
            render: (row) => new Date(row.updatedAt).toLocaleString()
        }
    ];

    // New head cells definition for the items table in the preview modal
    const itemsHeadCells = [
        {
            id: 'scheduleDayOffset',
            label: 'Send Day',
            initialVisible: true,
            render: (row) => (
                <Chip
                    label={`Day ${row.scheduleDayOffset}`}
                    color={row.scheduleDayOffset === 0 ? "primary" : "default"}
                    variant={row.scheduleDayOffset === 0 ? "filled" : "outlined"}
                    size="small"
                />
            )
        },
        {
            id: 'contentType',
            label: 'Content Type',
            initialVisible: true,
            render: (row) => (
                <Chip
                    label={row.contentType}
                    color={row.contentType === 'Simulation' ? 'primary' : 'warning'}
                    size="small"
                />
            )
        },
        {
            id: 'contentId',
            label: 'Content Name',
            initialVisible: true
        },
        {
            id: 'scheduledDate',
            label: 'Scheduled Date',
            initialVisible: true,
            render: (row) => {
                if (!startDate) return "-";

                try {
                    // Calculate the scheduled date based on start date and offset
                    const scheduledDate = addDays(new Date(startDate), row.scheduleDayOffset);
                    return formatDate(scheduledDate);
                } catch (error) {
                    console.error("Error calculating scheduled date:", error);
                    return "-";
                }
            }
        }
    ];

    // Toolbar actions
    const toolbarActions = [
        {
            render: () => (
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddPreset}
                >
                    New Preset Template
                </Button>
            )
        }
    ];

    return (
        <>
            <Table
                name="Preset Delivery Templates"
                data={presetData?.presets || []}
                error={error}
                loading={isLoading}
                headCells={headCells}
                search={true}
                selected={[]}
                setSelected={() => { }}
                checkbox={false}
                toolbarActions={[]}
                showToolbarActions={true}
                maxTableHeight="calc(100vh - 280px)"
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                rowCount={presetData?.totalRecords || 0}
            />

            { /* Preview Preset Dialog */}
            <Dialog
                open={presetDetailOpen}
                onClose={() => setPresetDetailOpen(false)}
                maxWidth="lg"
                fullWidth
                PaperProps={{
                    sx: {
                        width: '80%',
                        height: '90vh',
                        maxWidth: '1200px',
                        maxHeight: '1000px',
                        bgcolor: 'background.default' 
                    }
                }}
            >
                <DialogTitle>{selectedPreset?.name}</DialogTitle>
                <DialogContent sx={{ overflowY: 'auto' }}>
                    {selectedPreset && (
                        <>
                            {/* Title and date picker in one row */}
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                alignItems: { xs: 'flex-start', sm: 'center' },
                                justifyContent: 'space-between',
                                mt: 1,
                                mb: 1
                            }}>
                                <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
                                    Content Items ({selectedPreset.items?.length || 0})
                                </Typography>

                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    flexDirection: { xs: 'column', sm: 'row' }
                                }}>
                                    <TextField
                                        label="Start Date"
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            min: minStartDate,
                                        }}
                                        error={!!startDate && !isStartDateValid}
                                        helperText={startDate && !isStartDateValid ? "Please select a future date" : ""}
                                        sx={{
                                            width: '200px'
                                        }}
                                        size="small"
                                    />
                                    {startDate && isStartDateValid && (
                                        <Button 
                                            variant="outlined"
                                            size="medium"
                                            onClick={() => setStartDate('')}
                                            sx={{
                                                ml: { xs: 0, sm: 1 },
                                                mt: { xs: 1, sm: 0 },
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    )}
                                </Box>
                            </Box>

                            {/* Date explanation */}
                            {!startDate && (
                                <Box sx={{ mb: 1 }}>
                                    <Typography variant="body2" color="text.secondary">
                                        Select a start date to see when each item would be scheduled.
                                    </Typography>
                                </Box>
                            )}

                            {/* Replaced the direct table with the custom Table component */}
                            <Table
                                name=""
                                data={selectedPreset.items || []}
                                error={null}
                                loading={false}
                                headCells={itemsHeadCells}
                                search={false}
                                selected={[]}
                                setSelected={() => { }}
                                checkbox={false}
                                toolbarActions={[]}
                                showToolbarActions={false}
                                maxTableHeight="535px"
                                paginationModel={itemsPaginationModel}
                                setPaginationModel={setItemsPaginationModel}
                                rowCount={selectedPreset.items?.length || 0}
                                disablePagination={true}
                                size="small"
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setPresetDetailOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmation Dialog */}
            <ConfirmationDialog
                open={confirmDialogOpen}
                title={dialogTitle}
                content={dialogContent}
                onConfirm={() => {
                    setConfirmDialogOpen(false);
                    dialogAction?.();
                }}
                onCancel={() => setConfirmDialogOpen(false)}
            />
        </>
    );
};

PresetDeliveryTable.propTypes = {
    showNotification: PropTypes.func.isRequired
};

export default PresetDeliveryTable;