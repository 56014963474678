import React, { useState } from 'react';
import { Tabs, Tab, Box, Grid, Typography, Button } from '@mui/material';
import DomainsTable from "./DomainsTable"
import MainCard from "../../components/cards/MainCard";
import { useTheme } from "@mui/material/styles";
import { getCardColor } from "../../utils/colorUtils";
import { BRAND_TYPE } from "../../config";



function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`reports-tabpanel-${index}`}
            aria-labelledby={`reports-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `reports-tab-${index}`,
        'aria-controls': `reports-tabpanel-${index}`,
    };
}



export default function Deliverability({ data }) {
    const [subTabValue, setSubTabValue] = useState(0);
    const theme = useTheme();
    const lightMode = theme.palette.mode === "light";

    const handleSubTabChange = (event, newValue) => {
        setSubTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={subTabValue} onChange={handleSubTabChange} aria-label="reports tabs">
                    <Tab label="Sending Information" {...a11yProps(0)} />
                    <Tab label="Microsoft" {...a11yProps(1)} />
                    <Tab label="Google" {...a11yProps(2)} />
                    {BRAND_TYPE == "boxphish" && (<Tab label="Gateways" {...a11yProps(3)} />)}
                </Tabs>
            </Box>
            <TabPanel value={subTabValue} index={0}>
                <MainCard content={true} boxShadow={true} border={false}
                    sx={{
                        height: "100%",
                        bgcolor: getCardColor(lightMode),
                        mb: 3
                    }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "space-between"
                    }}>
                        <Box sx={{ display: "flex", alignItems: "left" }}>
                            <Typography variant="h5">
                                Sending IP: 23.249.219.118
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "left" }}>
                            <Typography variant="h5">
                                Header key: X-BPSAT
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "left" }}>
                            <Typography variant="h5">
                                Header value: 66Y7U72LTS
                            </Typography>
                        </Box>
                    </Box>
                </MainCard>
                <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                    <Grid item xs={12} md={12} lg={12}>
                        <DomainsTable />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={subTabValue} index={1}>
                <iframe
                    style={{ width: "100%", height: "80vh", border: "none" }}
                    title={`microsoft`}
                    src={BRAND_TYPE === "boxphish" ? 'https://boxphishsupport.helpdocs.io/article/d1g40drjo3-m-365-allowlisting' : 'https://help.clouduss.com/safelisting/microsoft-365-sat-safelisting' }
                />
            </TabPanel>
            <TabPanel value={subTabValue} index={2}>
                <iframe
                    style={{ width: "100%", height: "80vh", border: "none" }}
                    title={`google`}
                    src={BRAND_TYPE === "boxphish" ? 'https://boxphishsupport.helpdocs.io/article/gr96dprgbo-google-workspace' : 'https://help.clouduss.com/safelisting/google-workspace-sat-safelisting'}
                />
            </TabPanel>
            <TabPanel value={subTabValue} index={3}>
                <iframe
                    style={{ width: "100%", height: "80vh", border: "none" }}
                    title={`gateways`}
                    src={'https://boxphishsupport.helpdocs.io/article/zbvzcguyaw-allow-listing-links'}
                />
            </TabPanel>
            
        </Box>
    );
}