import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Box, Divider, List, Typography } from "@mui/material";

// project import
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const menu = useSelector((state) => state.menu);
    const { drawerOpen } = menu;

    // Safety check for item object
    if (!item || typeof item !== 'object') {
        return null;
    }

    // Safety check for children
    if (!item.children || !Array.isArray(item.children) || item.children.length === 0) {
        return null; // Don't render anything if no valid children
    }

    const navCollapse = item.children.map((menuItem) => {
        // Safety check for menuItem and required properties
        if (!menuItem || !menuItem.type || typeof menuItem !== 'object') {
            return null; // Skip invalid items
        }

        switch (menuItem.type) {
            case 'collapse':
                return <NavCollapse key={menuItem.id || `collapse-${Math.random()}`} menu={menuItem} level={1} />;
            case 'item':
                return <NavItem key={menuItem.id || `item-${Math.random()}`} item={menuItem} level={1} />;
            default:
                return (
                    <Typography key={menuItem.id || `error-${Math.random()}`} variant="h6" color="error" align="center">
                        Fix - Group Collapse or Items
                    </Typography>
                );
        }
    }).filter(Boolean); // Remove null items

    // If there are no valid menu items after filtering, don't render anything
    if (navCollapse.length === 0) {
        return null;
    }

    return (
        <>
            <List sx={{ mb: 0, py: 0, zIndex: 0 }}>
                {navCollapse}
            </List>

            {user?.role === 'Administrators' && item.divider && <Divider textAlign='center' variant='middle' sx={{ mt: 1, mb: 1 }} />}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;