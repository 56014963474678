import { useSelector } from "react-redux";
import tenancyAdminItems from './tenancyAdminItems';
import adminItems from './adminItems';
import userItems from "./userItems";

const useMenuItems = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { role } = user;
    const { isHierarchyTenancy } = useSelector((state) => state.tenancy);
    const items = [userItems];

    // Deep clone an object to ensure we don't modify the original
    const deepClone = (obj) => {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        const clone = Array.isArray(obj) ? [] : {};

        for (let key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                clone[key] = deepClone(obj[key]);
            }
        }

        return clone;
    };

    // Recursive filter function to handle both menu items and nested children
    const filterMenuItem = (item, userRole) => {
        if (!item) return null;

        // Create a copy to avoid modifying the original
        const itemCopy = deepClone(item);

        // Handle group type
        if (itemCopy.type === 'group') {
            if (!itemCopy.children || !Array.isArray(itemCopy.children)) {
                return itemCopy;
            }

            // Filter children of group
            const filteredChildren = itemCopy.children
                .map(child => filterMenuItem(child, userRole))
                .filter(Boolean); // Remove falsy values

            itemCopy.children = filteredChildren;
            return itemCopy;
        }

        // Handle item type
        if (itemCopy.type === 'item') {
            // Add specific check for learning-journeys1 - only show for Administrators
            if (itemCopy.id === 'learning-journeys1') {
                // Only allow for Administrators (super admins)
                if (userRole === 'Administrators') return itemCopy;
                return null; // Filter out for all other roles
            }

            if (itemCopy.id === 'reports1') {
                if (userRole.includes("TenancyAdministrators")) return user.enableReporting ? itemCopy : null;
                else return (user.reportingLevel !== null && user.enableReporting) ? itemCopy : null;
            }
            if (itemCopy.id === 'ondemand1') {
                if (userRole.includes("TenancyAdministrators")) return user.enableOndemand ? itemCopy : null;
                else return (user.onDemandLevel !== null && user.enableOndemand) ? itemCopy : null;
            }
            if (itemCopy.id === 'tenants1') {
                if (userRole.includes("PartnerAdministrators")) return itemCopy;
                else if (userRole.includes("TenancyAdministrators")) return isHierarchyTenancy ? itemCopy : null;
                return null;
            }
            return itemCopy; // Return the item if it passes all checks
        }

        // Handle collapse type
        if (itemCopy.type === 'collapse') {
            if (!itemCopy.children || !Array.isArray(itemCopy.children)) {
                return itemCopy;
            }

            // Filter the collapse children
            const filteredChildren = itemCopy.children
                .map(child => filterMenuItem(child, userRole))
                .filter(Boolean); // Remove null or undefined values

            // Only include this collapse item if it has at least one valid child
            if (filteredChildren.length > 0) {
                itemCopy.children = filteredChildren;
                return itemCopy;
            }

            return null; // Don't include collapse items with no children
        }

        // Default case: return the item unchanged
        return itemCopy;
    };

    // Add menu items based on user role
    if (role === 'Administrators') {
        // For full admins, add all items without filtering
        items.push(deepClone(tenancyAdminItems), deepClone(adminItems));
    } else if (role.includes('TenancyAdministrators') || role === '') {
        // For tenancy admins, filter the items
        const filteredTenancyAdminItems = filterMenuItem(deepClone(tenancyAdminItems), role);

        // Add the filtered items if it's valid
        if (filteredTenancyAdminItems &&
            filteredTenancyAdminItems.children &&
            filteredTenancyAdminItems.children.length > 0) {
            items.push(filteredTenancyAdminItems);
        }
    }

    return { items };
};

export default useMenuItems;