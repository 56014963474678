import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    MenuItem,
    Grid,
    Paper,
    CircularProgress,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    Chip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MainCard from "../../components/cards/MainCard";
import IosShareIcon from '@mui/icons-material/IosShare';
import {
    useGetDeliveryRequestByIdQuery,
    useUpdateDeliveryRequestMutation,
    useCreateDeliveryRequestMutation
} from '../../store/services/delivery-service';
import { useGetSimulationsQuery } from "../../store/services/simulation-service";
import { useGetCoursesQuery } from "../../store/services/university-service";
import { useTheme } from "@mui/material/styles";
import { ModalProvider, useModal } from '../../components/Modal/ModalContext';
import ContentPackCoursePreviewModal from '../content-packs/ContentPackCoursePreviewModal';
import { CalendarSimulationPreviewModal } from './CalendarSimulationPreview';
import ExportToPresetModal from './ExportToPresetModal';
import { getTextColor } from '../../utils/colorUtils';

// Constants for days of week and sizing
const DAYS_OF_WEEK = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
const CELL_SIZE = '32px'; // Base size for all cells
const CELL_SPACING = '16px'; // Used for both vertical and horizontal spacing
const NON_EMPTY_CELL_SCALE = 1.4; // Scale factor for non-empty cells (20% larger)
const MODAL_BORDER_RADIUS = '8px'; // Border radius for modal components

// Helper functions
const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
};

const formatDateForAPI = (date) => {
    if (!date) return null;
    const jsDate = new Date(date);
    jsDate.setHours(9); // Set to 9am
    jsDate.setMinutes(0);
    jsDate.setSeconds(0);
    return jsDate.toISOString();
};

const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
};

// Calendar day cell component - completely redesigned
const CalendarDay = memo(({ date, items, onDayClick, isMoveMode, contentTypeColors }) => {
    const [isHovered, setIsHovered] = useState(false);
    const theme = useTheme();
    const lightMode = theme.palette.mode == 'light'

    // Check if this date is today or in the past
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const cellDate = new Date(date);
    cellDate.setHours(0, 0, 0, 0);

    const isToday = today.getTime() === cellDate.getTime();
    const isPastOrToday = cellDate <= today;

    const handleClick = useCallback(() => {
        // Only allow click if date is in the future
        if (!isPastOrToday) {
            onDayClick(date, items);
        }
    }, [date, items, onDayClick, isPastOrToday]);

    // Determine if the cell should show content
    const isEmpty = items.length === 0;
    const showDayNumber = !isEmpty || isMoveMode || isHovered;

    // Calculate cell size with scaling for non-empty cells
    const cellScale = !isEmpty ? NON_EMPTY_CELL_SCALE : 1;

    // Color gradient for multiple items
    const getMultipleItemsColor = useCallback((items) => {
        if (items.length === 0) return 'transparent';
        if (items.length === 1) return contentTypeColors[items[0].contentType] || '#999';

        // For multiple items, create a gradient
        const colors = items.map(item => contentTypeColors[item.contentType] || '#999');
        return `linear-gradient(135deg, ${colors.join(', ')})`;
    }, [contentTypeColors]);

    // Determine background color based on items and date
    const backgroundColor = useMemo(() => {
        if (isPastOrToday) return isEmpty ? '#f0f0f0' : getMultipleItemsColor(items); // Lighter grey for past/today
        if (isMoveMode) return 'rgba(144, 202, 249, 0.15)';
        if (isEmpty) return theme.palette.mode == 'light' ? '#e0e0e0' : '#b0b0b0'; // Light grey for empty cells
        return items.length > 0 ? getMultipleItemsColor(items) : 'transparent';
    }, [items, isMoveMode, isEmpty, getMultipleItemsColor, isPastOrToday]);

    // Determine text color (white for dark backgrounds)
    const textColor = useMemo(() => {
        if (isPastOrToday && isEmpty) return '#999'; // Muted text for past/today empty cells
        if (items.length > 0 && !isMoveMode) return 'white';
        return 'inherit';
    }, [items, isMoveMode, isPastOrToday, isEmpty]);

    // Show indicators for multiple items
    const itemCount = items.length;

    return (
        <Box
            onClick={handleClick}
            onMouseEnter={() => !isPastOrToday && setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                width: CELL_SIZE,
                height: CELL_SIZE,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '6px', // Original border radius
                cursor: isPastOrToday ? 'default' : 'pointer',
                position: 'relative',
                backgroundImage: backgroundColor.includes('linear-gradient') ? backgroundColor : 'none',
                backgroundColor: !backgroundColor.includes('linear-gradient') ? backgroundColor : 'transparent',
                transition: 'all 0.2s',
                transform: isEmpty && !isMoveMode ? 'scale(1)' : `scale(${cellScale})`,
                zIndex: isEmpty ? 1 : 2, // Higher z-index for non-empty cells
                opacity: isPastOrToday ? 0.7 : 1, // Reduced opacity for past/today empty cells
                border: isToday ? '2px solid #ff9800' : 'none', // Orange border for today
                ...(isPastOrToday && {
                    // Apply diagonal strikethrough pattern for past dates if they're empty
                    backgroundImage: isEmpty ?
                        'repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) 5px, rgba(0, 0, 0, 0) 5px, rgba(0, 0, 0, 0) 10px)' :
                        (backgroundColor.includes('linear-gradient') ? backgroundColor : 'none'),
                }),
                '&:hover': {
                    opacity: isPastOrToday ? 0.7 : 1,
                    transform: isPastOrToday ?
                        (isEmpty && !isMoveMode ? 'scale(1)' : `scale(${cellScale})`) :
                        (isEmpty && !isMoveMode ? 'scale(1.05)' : `scale(${cellScale + 0.1})`),
                    boxShadow: isPastOrToday ? 'none' : theme.customShadows.z1,
                    backgroundColor: isPastOrToday ?
                        (!backgroundColor.includes('linear-gradient') ? backgroundColor : 'transparent') :
                        (isMoveMode ? 'rgba(144, 202, 249, 0.3)' : (isEmpty ? (lightMode ? '#cccccc' : '#707070') : undefined)),
                    zIndex: isPastOrToday ? (isEmpty ? 1 : 2) : 3 // Only increase z-index on hover for future dates
                }
            }}
        >
            {showDayNumber && (
                <Typography
                    variant="caption"
                    sx={{
                        color: textColor,
                        fontWeight: items.length > 0 ? 'bold' : 'normal',
                        zIndex: 1
                    }}
                >
                    {date.getDate()}
                </Typography>
            )}

            {/* Move mode indicator */}
            {isMoveMode && (
                <Box sx={{
                    position: 'absolute',
                    top: '1.5px',
                    right: '1.5px',
                    color: '#2196f3',
                    fontSize: '11px'
                }}>
                    <AddIcon fontSize="inherit" />
                </Box>
            )}

            {/* Count badge for multiple items */}
            {itemCount > 1 && (
                <Box sx={{
                    position: 'absolute',
                    bottom: '1.5px',
                    right: '1.5px',
                    bgcolor: 'rgba(255,255,255,0.8)',
                    color: '#333',
                    borderRadius: '50%',
                    width: '9px',
                    height: '9px',
                    fontSize: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold'
                }}>
                    {itemCount}
                </Box>
            )}
        </Box>
    );
});

const MonthLegendItem = memo(({ item, date, contentTypeColors }) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Box sx={{
                width: '20px',
                height: '20px',
                borderRadius: '4px', // Original border radius
                backgroundColor: contentTypeColors[item.contentType] || '#999',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Typography variant="caption" color="white" fontWeight="bold">
                    {date.getDate()}
                </Typography>
            </Box>
            <Typography variant="body2" noWrap sx={{ maxWidth: '200px' }}>
                {item.contentId}
            </Typography>
        </Box>
    );
});

// The main calendar component wrapped with ModalProvider
const ContentDeliveryCalendarWithProvider = (props) => {
    return (
        <ModalProvider>
            <ContentDeliveryCalendar {...props} />
        </ModalProvider>
    );
};

const ContentDeliveryCalendar = ({
    mode = 'edit', // 'edit', 'create', or 'preset'
    deliveryGroupId,
    onClose,
    showNotification,
    presetData,
    startDate
}) => {
    const { tenancyId } = useSelector((state) => state.tenancy);
    const theme = useTheme();
    const { activeModal, setActiveModal } = useModal();

    // New state for create mode
    const [deliveryGroupName, setDeliveryGroupName] = useState('');

    const contentTypeColors = useMemo(() => ({
        'Simulation': theme.palette.primary.main,
        'Course': theme.palette.warning.main,
        'Simulation-hover': theme.palette.primary.dark,
        'Course-hover': theme.palette.warning.dark
    }), [theme.palette.primary.main, theme.palette.warning.main, theme.palette.primary.dark, theme.palette.warning.dark]);

    // State management
    const [monthsToDisplay, setMonthsToDisplay] = useState([]);
    const [deliveryGroup, setDeliveryGroup] = useState(null);
    const [originalItems, setOriginalItems] = useState([]);
    const [calendarItems, setCalendarItems] = useState([]);
    const [changeTracker, setChangeTracker] = useState({
        updatedItems: [],
        itemsToRemove: [],
        newItems: []
    });
    const [modalState, setModalState] = useState({
        isAddModalOpen: false,
        isEditModalOpen: false,
        selectedDate: null,
        selectedItem: null,
        isMoveMode: false,
        itemToMove: null
    });
    const [formState, setFormState] = useState({
        contentId: '',
        contentType: 'Simulation',
        scheduledFor: '',
        selectedContentName: '' // New field to store the selected simulation/course name
    });
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    // Preview state
    const [coursePreviewOpen, setCoursePreviewOpen] = useState(false);
    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedSimId, setSelectedSimId] = useState(null);
    const [prevActiveModal, setPrevActiveModal] = useState(null); // Track previous modal state
    const [showBanner, setShowBanner] = useState(true);
    const [attemptedSave, setAttemptedSave] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [exportPresetOpen, setExportPresetOpen] = useState(false);

    const handleExportToPresetOpen = () => {
        if (calendarItems.length === 0) {
            showNotification('Cannot create a preset with no items', 'error');
            return;
        }
        setExportPresetOpen(true);
    };

    // Pagination state
    const [pagination, setPagination] = useState({
        currentPage: 0,
        monthsPerPage: 6
    });

    // Derived state for cleaner code
    const { updatedItems, itemsToRemove, newItems } = changeTracker;
    const {
        isAddModalOpen, isEditModalOpen, selectedDate,
        selectedItem, isMoveMode, itemToMove
    } = modalState;

    // RTK Query hooks for simulations and courses
    const { data: simData } = useGetSimulationsQuery({
        perPage: 200,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    const { data: courseData } = useGetCoursesQuery({
        perPage: 200,
        page: 0,
        includes: "",
        query: "",
        additionalQueryParams: ""
    });

    // RTK Query hooks
    const {
        data: deliveryRequestData,
        isLoading: isLoadingRequest,
        error: requestError,
        refetch: refetchDeliveryRequest
    } = useGetDeliveryRequestByIdQuery(
        {
            tenancyId,
            requestId: deliveryGroupId
        },
        { skip: !tenancyId || !deliveryGroupId || mode === 'create' }
    );

    const [
        updateDeliveryRequest,
        { isLoading: isUpdating }
    ] = useUpdateDeliveryRequestMutation();

    // Add create mutation
    const [
        createDeliveryRequest,
        { isLoading: isCreating }
    ] = useCreateDeliveryRequestMutation();

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        setIsAdmin(user && user.role === "Administrators");
    }, []);


    useEffect(() => {
        if (mode === 'preset' && presetData && startDate) {
            // Initialize with preset data
            setDeliveryGroup(null);
            setOriginalItems([]);

            // Parse date properly
            const presetStartDate = new Date(startDate);

            // Convert preset items to calendar items
            const calendarItemsFromPreset = presetData.items.map((item, index) => {
                // Calculate date based on start date and offset
                const itemDate = new Date(presetStartDate);
                itemDate.setDate(presetStartDate.getDate() + item.scheduleDayOffset);

                // Format date properly for API
                const scheduledDate = formatDateForAPI(itemDate);

                // Create a temporary ID for the new item
                const tempId = `temp-${Date.now()}-${index}`;

                return {
                    id: tempId,
                    requestId: null,
                    contentId: item.contentId,
                    contentType: item.contentType,
                    recipientType: "Tenancy",
                    sendToId: tenancyId.toString(),
                    status: "Pending",
                    scheduledFor: scheduledDate,
                    sentAt: null,
                    error: null,
                    attempts: 0,
                    lastAttemptAt: null
                };
            });

            setCalendarItems(calendarItemsFromPreset);

            // Set delivery group name from preset
            setDeliveryGroupName(`${presetData.name} - ${new Date(startDate).toLocaleDateString()}`);

            // Generate months to display starting from the selected start date
            const months = [];
            const currentDate = new Date(startDate);
            for (let i = 0; i < 12; i++) {
                // Calculate month and year directly to avoid date adjustment issues
                const monthValue = (currentDate.getMonth() + i) % 12;
                const yearValue = currentDate.getFullYear() + Math.floor((currentDate.getMonth() + i) / 12);

                months.push({
                    year: yearValue,
                    month: monthValue
                });
            }
            setMonthsToDisplay(months);

            // Reset pagination to show the first page (containing start date)
            setPagination(prev => ({
                ...prev,
                currentPage: 0
            }));

            setUnsavedChanges(true);
        }
    }, [mode, presetData, startDate, tenancyId]);

    // Initialize empty state for create mode
    useEffect(() => {
        if (mode === 'create') {
            setDeliveryGroup(null);
            setOriginalItems([]);
            setCalendarItems([]);
            setChangeTracker({
                updatedItems: [],
                itemsToRemove: [],
                newItems: []
            });

            // Set initial months to display for the current and next few months
            const currentDate = new Date();
            const months = [];
            for (let i = 0; i < 12; i++) {
                // Calculate month and year directly to avoid date adjustment issues
                const monthValue = (currentDate.getMonth() + i) % 12;
                const yearValue = currentDate.getFullYear() + Math.floor((currentDate.getMonth() + i) / 12);

                months.push({
                    year: yearValue,
                    month: monthValue
                });
            }
            setMonthsToDisplay(months);
        }
    }, [mode]);

    // Create an indexed map of items by date for faster lookups
    const itemsByDate = useMemo(() => {
        const map = new Map();

        calendarItems.forEach(item => {
            if (!item.scheduledFor) return;

            const dateKey = formatDate(item.scheduledFor);
            if (!map.has(dateKey)) {
                map.set(dateKey, []);
            }
            map.get(dateKey).push(item);
        });

        // Sort items by content type within each date
        map.forEach(items => {
            items.sort((a, b) => a.contentType.localeCompare(b.contentType));
        });

        return map;
    }, [calendarItems]);

    // Calculate start date and generate months to display for edit mode
    useEffect(() => {
        if (mode === 'create' || !originalItems || originalItems.length === 0) return;

        // Find the earliest scheduled date
        let earliestDate = new Date();

        // Check all items for the earliest date
        originalItems.forEach(item => {
            if (item.scheduledFor) {
                const itemDate = new Date(item.scheduledFor);
                if (itemDate < earliestDate) {
                    earliestDate = itemDate;
                }
            }
        });

        // Set to the first day of the month
        const startMonth = new Date(earliestDate.getFullYear(), earliestDate.getMonth(), 1);

        // Calculate end date (3 years from earliest date)
        const endMonth = new Date(startMonth);
        endMonth.setFullYear(endMonth.getFullYear() + 3);

        // Generate all months between start and end
        const months = [];
        const currentMonth = new Date(startMonth);

        while (currentMonth < endMonth) {
            months.push({
                year: currentMonth.getFullYear(),
                month: currentMonth.getMonth()
            });
            currentMonth.setMonth(currentMonth.getMonth() + 1);
        }

        setMonthsToDisplay(months);

        // Reset pagination to start at the beginning
        setPagination(prev => ({
            ...prev,
            currentPage: 0
        }));
    }, [originalItems, mode]);

    // Calculate total pages
    const totalPages = useMemo(() =>
        Math.ceil(monthsToDisplay.length / pagination.monthsPerPage),
        [monthsToDisplay.length, pagination.monthsPerPage]);

    // Get current months to display
    const currentMonths = useMemo(() => {
        const startIdx = pagination.currentPage * pagination.monthsPerPage;
        return monthsToDisplay.slice(startIdx, startIdx + pagination.monthsPerPage);
    }, [monthsToDisplay, pagination.currentPage, pagination.monthsPerPage]);

    // Handle pagination
    const handlePreviousPage = useCallback(() => {
        setPagination(prev => ({
            ...prev,
            currentPage: Math.max(0, prev.currentPage - 1)
        }));
    }, []);

    const handleNextPage = useCallback(() => {
        setPagination(prev => ({
            ...prev,
            currentPage: Math.min(totalPages - 1, prev.currentPage + 1)
        }));
    }, [totalPages]);

    // Error handling
    useEffect(() => {
        if (requestError) {
            console.error("Error fetching delivery request:", requestError);
            showNotification(`Error loading calendar data: ${requestError.error || 'Unknown error'}`, 'error');
        }
    }, [requestError, showNotification]);

    // Process delivery request data when it's loaded in edit mode
    useEffect(() => {
    if (mode === 'edit' && deliveryRequestData) {
        setDeliveryGroup(deliveryRequestData.request);
        setOriginalItems(deliveryRequestData.items || []);
        
        // Filter out cancelled items for display
        const activeItems = deliveryRequestData.items 
            ? deliveryRequestData.items.filter(item => item.status !== 'Cancelled')
            : [];
        
        setCalendarItems(activeItems);
        
        // Reset tracking arrays when new data is loaded
        setChangeTracker({
            updatedItems: [],
            itemsToRemove: [],
            newItems: []
        });
        setUnsavedChanges(false);
    }
}, [deliveryRequestData, mode]);

    // Preview function
    const handlePreview = useCallback(() => {
        if (!formState.contentId) return;

        // Save current modal state before changing to course preview
        if (activeModal && !['course', 'simulation'].includes(activeModal)) {
            setPrevActiveModal(activeModal);
        } else if (isAddModalOpen) {
            setPrevActiveModal('add');
        } else if (isEditModalOpen) {
            setPrevActiveModal('edit');
        }

        if (formState.contentType === 'Course') {
            // Find the course ID based on the course name
            const course = courseData?.find(course => course.courseName === formState.contentId);
            if (course) {
                // Set the course ID first, then set the modal state
                setSelectedCourseId(course.id);

                // Small timeout to prevent flashing during state transitions
                setTimeout(() => {
                    setCoursePreviewOpen(true);
                    setActiveModal('course');
                }, 50);

                console.log(`Previewing course: ${formState.contentId} (ID: ${course.id})`);
            }
        } else if (formState.contentType === 'Simulation') {
            // Find the simulation ID based on the simulation name
            const simulation = simData?.find(sim => sim.simulationName === formState.contentId);
            if (simulation) {
                // Set the simulation ID first, then set the modal state
                setSelectedSimId(simulation.id);

                // Small timeout to prevent flashing during state transitions
                setTimeout(() => {
                    setActiveModal('simulation');
                }, 50);

                console.log(`Previewing simulation: ${formState.contentId} (ID: ${simulation.id})`);
            }
        }
    }, [formState.contentId, formState.contentType, courseData, simData, setActiveModal, activeModal, isAddModalOpen, isEditModalOpen]);

    // Handle close preview modal
    const handleClosePreview = useCallback(() => {
        setCoursePreviewOpen(false);
        setSelectedCourseId(null);
        setSelectedSimId(null);

        // Restore previous modal state
        if (prevActiveModal) {
            setActiveModal(prevActiveModal);
            setPrevActiveModal(null);
        } else {
            setActiveModal(null);
        }
    }, [setActiveModal, prevActiveModal]);

    // EVENT HANDLERS

    // Add state for multi-item selection modal
    const [multiItemSelectState, setMultiItemSelectState] = useState({
        isOpen: false,
        items: [],
        date: null,
        readOnly: false // Added readOnly flag for past dates
    });

    // Move item to the selected date
    const handleMoveItemToDate = useCallback((date) => {
        if (!itemToMove) return;

        // Check if target date is today or in the past
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const targetDate = new Date(date);
        targetDate.setHours(0, 0, 0, 0);

        if (targetDate <= today) {
            showNotification("Cannot move items to past dates or today", "error");
            return;
        }

        // Format date properly for API
        const scheduledDate = formatDateForAPI(date);

        // Create the updated item
        const updatedItem = {
            ...itemToMove,
            scheduledFor: scheduledDate
        };

        // Update in calendar items
        setCalendarItems(prev =>
            prev.map(item => item.id === itemToMove.id ? updatedItem : item)
        );

        // Track for API update
        trackItemUpdate(updatedItem);

        // Exit move mode
        setModalState(prev => ({
            ...prev,
            isMoveMode: false,
            itemToMove: null
        }));

        setUnsavedChanges(true);

        // Show success notification
        showNotification('Item moved successfully', 'success');
    }, [itemToMove, showNotification]);

    // Helper to check if a date is today or in the past
    const isDateTodayOrPast = useCallback((date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const checkDate = new Date(date);
        checkDate.setHours(0, 0, 0, 0);

        return checkDate <= today;
    }, []);

    // Handle day click based on current mode
    const handleDayClick = useCallback((date, itemsAtDate = []) => {
        // First check if the date is today or in the past
        if (isDateTodayOrPast(date)) {
            // Only allow viewing existing items for past dates
            if (itemsAtDate.length >= 1) {
                // Allow viewing but not editing
                setMultiItemSelectState({
                    isOpen: true,
                    items: itemsAtDate,
                    date: date,
                    readOnly: true // Add readOnly flag for past dates
                });
            } else {
                // For empty past cells, show notification that past dates can't be modified
                showNotification("Cannot add items to past dates or today", "warning");
            }
            return;
        }

        // Proceed normally for future dates
        if (isMoveMode && itemToMove) {
            // Move the item to the clicked date
            handleMoveItemToDate(date);
        } else if (itemsAtDate.length >= 1) {
            // If one or more items on this date, open selection modal
            setMultiItemSelectState({
                isOpen: true,
                items: itemsAtDate,
                date: date,
                readOnly: false
            });
        } else {
            // Normal mode - open add modal for empty cell
            setModalState(prev => ({
                ...prev,
                isAddModalOpen: true,
                selectedDate: date
            }));
            setFormState(prev => ({
                ...prev,
                contentId: '',
                contentType: 'Simulation',
                scheduledFor: formatDate(date),
                selectedContentName: ''
            }));
        }
    }, [isMoveMode, itemToMove, handleMoveItemToDate, isDateTodayOrPast, showNotification]);

    // Handle item click to edit
    const handleItemClick = useCallback((item) => {
        if (isMoveMode) return; // Ignore item clicks in move mode

        // Check if the item's scheduled date is today or in the past
        const isPastDate = isDateTodayOrPast(item.scheduledFor);

        if (isPastDate) {
            // Show notification that past items can't be edited
            showNotification("Items scheduled for past dates or today cannot be edited", "warning");
            return;
        }

        setModalState(prev => ({
            ...prev,
            isEditModalOpen: true,
            selectedItem: item
        }));
        setFormState({
            contentId: item.contentId,
            contentType: item.contentType,
            scheduledFor: formatDate(item.scheduledFor),
            selectedContentName: item.contentId
        });
    }, [isMoveMode, isDateTodayOrPast, showNotification]);

    // Enter move mode from edit modal
    const handleEnterMoveMode = useCallback(() => {
        if (!selectedItem) return;

        setModalState(prev => ({
            ...prev,
            isMoveMode: true,
            itemToMove: selectedItem,
            isEditModalOpen: false
        }));

        // Show notification to guide the user
        showNotification('Select a day to move this item to', 'info');
    }, [selectedItem, showNotification]);

    // Cancel move mode
    const handleCancelMoveMode = useCallback(() => {
        setModalState(prev => ({
            ...prev,
            isMoveMode: false,
            itemToMove: null
        }));
    }, []);

    // Track item updates for saving
    const trackItemUpdate = useCallback((updatedItem) => {
        // In create mode, all items are new
        if (mode === 'create') {
            // Create mode logic remains unchanged
            setChangeTracker(prev => {
                // Find if this item already exists in newItems by id
                const existsInNewItems = prev.newItems.some(item => item.id === updatedItem.id);

                if (existsInNewItems) {
                    // Update in the newItems array
                    return {
                        ...prev,
                        newItems: prev.newItems.map(item =>
                            item.id === updatedItem.id ? updatedItem : item
                        )
                    };
                }

                return prev;
            });
            return;
        }

        // For edit mode, use the original tracking logic
        // Check if this is a new item (it might already be in the newItems array)
        if (updatedItem.id.includes('temp-')) {
            setChangeTracker(prev => ({
                ...prev,
                newItems: prev.newItems.map(item =>
                    item.id === updatedItem.id ? updatedItem : item
                )
            }));
            return;
        }

        // Check if this is an existing item
        const isExistingItem = originalItems.some(item => item.id === updatedItem.id);

        if (isExistingItem) {
            // Find the original item to compare changes
            const originalItem = originalItems.find(item => item.id === updatedItem.id);

            setChangeTracker(prev => {
                // Check if we've already marked this item for update
                const existingUpdateIndex = prev.updatedItems.findIndex(
                    item => item.itemId === updatedItem.id
                );

                // Create the update data, only including properties that have changed
                const updateData = {
                    itemId: updatedItem.id,
                    status: updatedItem.status,
                    scheduledFor: updatedItem.scheduledFor,
                    recipientType: "Tenancy",
                    sendToId: tenancyId.toString()
                };

                // Only include contentType if it's different from the original
                if (originalItem && updatedItem.contentType !== originalItem.contentType) {
                    updateData.contentType = updatedItem.contentType;
                }

                // Only include contentId if it's different from the original
                if (originalItem && updatedItem.contentId !== originalItem.contentId) {
                    updateData.contentId = updatedItem.contentId;
                }

                if (existingUpdateIndex >= 0) {
                    // Update the existing update
                    const newUpdatedItems = [...prev.updatedItems];
                    newUpdatedItems[existingUpdateIndex] = updateData;

                    return {
                        ...prev,
                        updatedItems: newUpdatedItems
                    };
                } else {
                    // Add a new update
                    return {
                        ...prev,
                        updatedItems: [...prev.updatedItems, updateData]
                    };
                }
            });
        }
    }, [originalItems, tenancyId, mode]);

    // Handle form input changes
    const handleFormChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormState(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    // Handle content type change
    const handleContentTypeChange = useCallback((e) => {
        setFormState(prev => ({
            ...prev,
            contentType: e.target.value,
            selectedContentName: '', // Reset content selection when type changes
            contentId: '' // Reset contentId when type changes
        }));
    }, []);

    // Handle content selection change
    const handleContentSelection = useCallback((e) => {
        const selectedName = e.target.value;
        setFormState(prev => ({
            ...prev,
            selectedContentName: selectedName,
            contentId: selectedName // Set contentId to the selected name
        }));
    }, []);

    // Add a new item
    const handleAddItem = useCallback(() => {
        // Create a temporary ID for the new item
        const tempId = `temp-${Date.now()}`;

        // Format date properly
        const scheduledDate = formatDateForAPI(formState.scheduledFor);

        // Create the new item
        const newItem = {
            id: tempId,
            requestId: mode === 'edit' ? deliveryGroupId : null,
            contentId: formState.contentId,
            contentType: formState.contentType,
            recipientType: "Tenancy",
            sendToId: tenancyId.toString(),
            status: "Pending",
            scheduledFor: scheduledDate,
            sentAt: null,
            error: null,
            attempts: 0,
            lastAttemptAt: null
        };

        // Add to calendar items for display
        setCalendarItems(prev => [...prev, newItem]);

        // Track for saving to API
        if (mode === 'edit') {
            setChangeTracker(prev => ({
                ...prev,
                newItems: [...prev.newItems, {
                    contentId: formState.contentId,
                    contentType: formState.contentType,
                    recipientType: "Tenancy",
                    sendToId: tenancyId.toString(),
                    scheduledFor: scheduledDate
                }]
            }));
        } else {
            // For create mode, all items are tracked in calendarItems
            setUnsavedChanges(true);
        }

        // Hide the banner when adding the first item
        if (mode === 'create' && showBanner) {
            setShowBanner(false);
        }

        setModalState(prev => ({
            ...prev,
            isAddModalOpen: false
        }));
        setUnsavedChanges(true);
    }, [formState, deliveryGroupId, tenancyId, mode, showBanner]);

    // Update an existing item
    const handleUpdateItem = useCallback(() => {
        if (!selectedItem) return;

        // Format date properly
        const scheduledDate = formatDateForAPI(formState.scheduledFor);

        // Create the updated item
        const updatedItem = {
            ...selectedItem,
            contentId: formState.contentId,
            contentType: formState.contentType,
            recipientType: "Tenancy",
            sendToId: tenancyId.toString(),
            scheduledFor: scheduledDate
        };

        // Update in calendar items
        setCalendarItems(prev =>
            prev.map(item => item.id === selectedItem.id ? updatedItem : item)
        );

        // Track for API update
        trackItemUpdate(updatedItem);

        setModalState(prev => ({
            ...prev,
            isEditModalOpen: false
        }));
        setUnsavedChanges(true);
    }, [selectedItem, formState, trackItemUpdate, tenancyId]);

    // Delete an item
    const handleDeleteItem = useCallback(() => {
        if (!selectedItem) return;

        // Remove from calendar items
        setCalendarItems(prev =>
            prev.filter(item => item.id !== selectedItem.id)
        );

        if (mode === 'edit') {
            setChangeTracker(prev => {
                // If it's a new item (has temp ID), remove from newItems
                if (selectedItem.id.includes('temp-')) {
                    return {
                        ...prev,
                        newItems: prev.newItems.filter(item =>
                            !(item.contentId === selectedItem.contentId &&
                                item.scheduledFor === selectedItem.scheduledFor)
                        )
                    };
                } else {
                    // Otherwise add to items to remove
                    return {
                        ...prev,
                        itemsToRemove: [...prev.itemsToRemove, selectedItem.id],
                        // Remove from updatedItems if it's there
                        updatedItems: prev.updatedItems.filter(item =>
                            item.itemId !== selectedItem.id
                        )
                    };
                }
            });
        }

        setModalState(prev => ({
            ...prev,
            isEditModalOpen: false
        }));
        setUnsavedChanges(true);
    }, [selectedItem, mode]);

    // Close any modal
    const handleCloseModal = useCallback((modalType) => {
        // If we're closing a modal, reset any active modal tracking
        if (
            (modalType === 'isAddModalOpen' && activeModal === 'add') ||
            (modalType === 'isEditModalOpen' && activeModal === 'edit')
        ) {
            setActiveModal(null);
        }

        setModalState(prev => ({
            ...prev,
            [modalType]: false
        }));
    }, [activeModal, setActiveModal]);

    // Save all changes using correct API
    const handleSaveChanges = useCallback(async () => {
        // Set the attempted save flag
        setAttemptedSave(true);

        // Validate name field for all modes
        if (mode === 'create' || mode === 'preset') {
            // For create/preset modes, check the deliveryGroupName state
            if (!deliveryGroupName.trim()) {
                showNotification('Please provide a name for the delivery group', 'error');
                return;
            }
        } else if (mode === 'edit' && deliveryGroup) {
            // For edit mode, validate the name in the deliveryGroup object
            if (!deliveryGroup.name || !deliveryGroup.name.trim()) {
                showNotification('Delivery group name cannot be empty', 'error');
                return;
            }
        }

        // Check if there are no items in create mode
        if ((mode === 'create' || mode === 'preset') && calendarItems.length === 0) {
            showNotification('Please add at least one content item to the calendar', 'error');
            return;
        }

        try {
            if (mode === 'edit' && deliveryGroup) {
                // Edit mode - existing logic
                const requestData = {
                    name: deliveryGroup.name,
                    updatedById: deliveryGroup.createdById,
                    itemUpdates: updatedItems,
                    newItems: newItems.map(item => ({
                        ...item,
                        recipientType: "Tenancy",
                        sendToId: tenancyId.toString()
                    })),
                    itemIdsToRemove: itemsToRemove,
                    pausedContentTypes: deliveryGroup.pausedContentTypes || []
                };

                await updateDeliveryRequest({
                    tenancyId: tenancyId,
                    requestId: deliveryGroupId,
                    requestData: requestData
                }).unwrap();

                // Refetch to get updated data
                await refetchDeliveryRequest();
            } else if (mode === 'create' || mode === 'preset') {
                // Create mode - new logic
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user ? user.email : '';

                const requestData = {
                    name: deliveryGroupName,
                    type: "Template", // Always set to Template for create mode
                    createdById: userId,
                    deliveryItems: calendarItems.map(item => ({
                        contentId: item.contentId,
                        contentType: item.contentType,
                        recipientType: "Tenancy",
                        sendToId: tenancyId.toString(),
                        scheduledFor: item.scheduledFor
                    }))
                };

                await createDeliveryRequest({
                    tenancyId: tenancyId,
                    requestData: requestData
                }).unwrap();
            }

            // Reset tracking arrays
            setChangeTracker({
                updatedItems: [],
                itemsToRemove: [],
                newItems: []
            });
            setUnsavedChanges(false);

            showNotification(`Delivery request ${mode === 'create' ? 'created' : 'updated'} successfully`, 'success');

            // Pass success to onClose
            onClose(true);
        } catch (error) {
            console.error(`Error ${mode === 'create' ? 'creating' : 'saving changes to'} delivery request:`, error);
            showNotification(`Error ${mode === 'create' ? 'creating' : 'saving changes to'} delivery request: ` + (error.message || 'Unknown error'), 'error');
        }
    }, [
        mode, deliveryGroup, deliveryGroupName, calendarItems,
        updatedItems, newItems, itemsToRemove,
        tenancyId, deliveryGroupId, updateDeliveryRequest, createDeliveryRequest,
        refetchDeliveryRequest, showNotification, onClose
    ]);

    // RENDERING FUNCTIONS

    // Generate day cells for a month - memoized for performance
    const generateDays = useCallback((year, month) => {
        const daysInMonth = getDaysInMonth(year, month);

        // Get the day of the week for the first day (0 = Sunday, 1 = Monday, etc.)
        let firstDayOfMonth = new Date(year, month, 1).getDay();

        // Adjust for Monday start (convert Sunday from 0 to 7)
        firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;

        // Array for empty grid spaces before first day of month
        const emptyCells = Array(firstDayOfMonth).fill(null).map((_, index) => (
            <Box
                key={`empty-${index}`}
                sx={{
                    visibility: 'hidden',
                    width: CELL_SIZE,
                    height: CELL_SIZE
                }}
            />
        ));

        // Array for actual days
        const dayCells = Array(daysInMonth).fill(null).map((_, index) => {
            const day = index + 1;
            const date = new Date(year, month, day);
            const dateString = formatDate(date);

            // Get items for this day from our map for faster lookup
            const dayItems = itemsByDate.get(dateString) || [];

            return (
                <CalendarDay
                    key={dateString}
                    date={date}
                    items={dayItems}
                    onDayClick={handleDayClick}
                    isMoveMode={isMoveMode}
                    contentTypeColors={contentTypeColors}
                />
            );
        });

        // Fill the remaining cells of the last week with empty grid spaces
        const totalCells = emptyCells.length + dayCells.length;
        const remainingCells = 7 - (totalCells % 7);
        const fillCells = remainingCells < 7 ? Array(remainingCells).fill(null).map((_, index) => (
            <Box
                key={`fill-${index}`}
                sx={{
                    visibility: 'hidden',
                    width: CELL_SIZE,
                    height: CELL_SIZE
                }}
            />
        )) : [];

        return [...emptyCells, ...dayCells, ...fillCells];
    }, [itemsByDate, isMoveMode, handleDayClick, contentTypeColors]);

    // Render calendar month component - memoized for performance
    const renderCalendarMonth = useCallback((year, month) => {
        // Get items for this month for the legend
        const monthStart = new Date(year, month, 1);

        // Create monthEnd as the last day of the month, but set time to 23:59:59
        const monthEnd = new Date(year, month + 1, 0);
        monthEnd.setHours(23, 59, 59, 999);


        // Filter and deduplicate items for legend
        const monthItems = calendarItems.filter(item => {
            if (!item.scheduledFor) return false;
            const itemDate = new Date(item.scheduledFor);
            return itemDate >= monthStart && itemDate <= monthEnd;
        });

        // Get unique content items for legend
        const uniqueContentMap = new Map();
        monthItems.forEach(item => {
            if (!uniqueContentMap.has(item.contentId)) {
                uniqueContentMap.set(item.contentId, item);
            }
        });

        const uniqueItems = Array.from(uniqueContentMap.values());

        // Sort by scheduled date
        uniqueItems.sort((a, b) => new Date(a.scheduledFor) - new Date(b.scheduledFor));

        // Calculate calendar grid width for consistent sizing
        // Add some extra space to accommodate the scaled cells
        const extraSpace = 2 * NON_EMPTY_CELL_SCALE * parseInt(CELL_SIZE); // Extra space for scaled cells
        const calendarWidth = `calc(7 * ${CELL_SIZE} + 6 * ${CELL_SPACING} + ${extraSpace}px)`;

        return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={`${year}-${month}`}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <Typography variant="h4" gutterBottom>
                        {new Date(year, month).toLocaleDateString('default', { month: 'long' })}
                    </Typography>

                    {/* Calendar container with fixed width */}
                    <Box sx={{
                        width: calendarWidth,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {/* Days of week header - updated styling */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(7, ${CELL_SIZE})`,
                            gap: CELL_SPACING,
                            width: '100%',
                            mb: CELL_SPACING
                        }}>
                            {DAYS_OF_WEEK.map((day, i) => (
                                <Box key={i} sx={{
                                    width: CELL_SIZE,
                                    height: CELL_SIZE,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <Typography variant="caption" fontWeight="bold">
                                        {day}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                        {/* Calendar days - extra padding around to accommodate scaled cells */}
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: `repeat(7, ${CELL_SIZE})`,
                            gap: CELL_SPACING,
                            width: '100%',
                            padding: `${Math.floor(parseInt(CELL_SIZE) * (NON_EMPTY_CELL_SCALE - 1) / 2)}px`,
                            position: 'relative' // For positioning of scaled elements
                        }}>
                            {generateDays(year, month)}
                        </Box>

                        {/* Legend with proper alignment */}
                        {uniqueItems.length > 0 && (
                            <Box sx={{
                                mt: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                width: '100%'
                            }}>
                                {uniqueItems.map(item => (
                                    <MonthLegendItem
                                        key={item.id}
                                        item={item}
                                        date={new Date(item.scheduledFor)}
                                        contentTypeColors={contentTypeColors}
                                    />
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Grid>
        );
    }, [calendarItems, generateDays, contentTypeColors]);

    // Render the paginated calendar grid
    const renderPaginatedCalendarGrid = useMemo(() => {
        // Group months by year for better organization
        const monthsByYear = {};
        currentMonths.forEach(({ year, month }) => {
            if (!monthsByYear[year]) {
                monthsByYear[year] = [];
            }
            // Only add the month if it's not already in the array
            if (!monthsByYear[year].includes(month)) {
                monthsByYear[year].push(month);
            }
        });

        // Sort the months in each year to ensure correct order
        Object.keys(monthsByYear).forEach(year => {
            monthsByYear[year].sort((a, b) => a - b);
        });

        return Object.entries(monthsByYear).map(([year, months]) => (
            <React.Fragment key={year}>
                <Grid item xs={12}>
                    <Typography variant="h2" gutterBottom sx={{ mt: 2 }}>
                        {year}
                    </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 4 }}>
                    {months.map(monthIndex => renderCalendarMonth(parseInt(year), monthIndex))}
                </Grid>
            </React.Fragment>
        ));
    }, [currentMonths, renderCalendarMonth]);

    // Pagination controls component
    const PaginationControls = useCallback(() => (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            my: 2,
            gap: 2
        }}>
            <Button
                variant="contained"
                onClick={handlePreviousPage}
                disabled={pagination.currentPage === 0}
                color={isMoveMode ? "info" : "primary"}
                sx={isMoveMode ? { borderColor: 'info.main' } : {}}
            >
                Previous
            </Button>

            <Typography variant="body1" color={isMoveMode ? "info.main" : "text.primary"}>
                Page {pagination.currentPage + 1} of {totalPages}
                {isMoveMode && " (Move Mode)"}
            </Typography>

            <Button
                variant="contained"
                onClick={handleNextPage}
                disabled={pagination.currentPage >= totalPages - 1}
                color={isMoveMode ? "info" : "primary"}
                sx={isMoveMode ? { borderColor: 'info.main' } : {}}
            >
                Next
            </Button>
        </Box>
    ), [pagination.currentPage, totalPages, isMoveMode, handlePreviousPage, handleNextPage]);

    // Loading state
    if (isLoadingRequest && mode === 'edit') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 4 }}>
                <CircularProgress />
                <Typography variant="body1" sx={{ ml: 2 }}>
                    Loading calendar data...
                </Typography>
            </Box>
        );
    }

    // Error state
    if (requestError && mode === 'edit') {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', p: 4 }}>
                <Typography variant="h6" color="error">
                    Error loading calendar: {requestError.error || 'Unknown error'}
                </Typography>
            </Box>
        );
    }

    // Main render
    return (
        <Box>
            <MainCard
                title={
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                {isMoveMode ? (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        startIcon={<CancelIcon />}
                                        onClick={handleCancelMoveMode}
                                        sx={{ mr: 1 }}
                                    >
                                        Cancel Move
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<SaveIcon />}
                                        onClick={handleSaveChanges}
                                        disabled={
                                            mode === 'create'
                                                ? !deliveryGroupName || calendarItems.length === 0 || isCreating
                                                : !unsavedChanges || isUpdating
                                        }
                                        sx={{ mr: 1 }}
                                    >
                                        {isUpdating || isCreating ?
                                                <CircularProgress size={24} sx={{ color: getTextColor(theme.palette.primary.main) }} /> :
                                        ((mode === 'create' || mode === 'preset') ? 'Create & Apply' : 'Save Changes')}
                                    </Button>
                                )}
                                {isAdmin && (
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<IosShareIcon />}
                                        onClick={handleExportToPresetOpen}
                                        disabled={calendarItems.length === 0 || isUpdating || isCreating || isMoveMode}
                                        sx={{ mr: 1 }}
                                    >
                                        Export to Preset
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() => onClose(false)}
                                    disabled={isMoveMode || isUpdating || isCreating}
                                >
                                    Cancel
                                </Button>
                            </Box>
                            <Box sx={{ textAlign: 'start' }}>
                                {mode === 'create' || mode === 'preset' ? (
                                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            maxWidth: '200px'
                                        }}>
                                        </Box>
                                        <TextField
                                            label="Delivery Group Name"
                                            value={deliveryGroupName}
                                            onChange={(e) => setDeliveryGroupName(e.target.value)}
                                            required
                                            error={attemptedSave && !deliveryGroupName.trim()}
                                            helperText={attemptedSave && !deliveryGroupName.trim() ? "Name is required" : ""}
                                            sx={{ width: '300px' }}
                                        />
                                    </Box>
                                ) : (
                                    <Typography variant="h4">
                                        {deliveryRequestData?.request ? deliveryRequestData.request.name : "Content Delivery"}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Box>
                }
                content={true}
                sx={{ bgcolor: 'background.default' }}
            >

                {mode === 'preset' && showBanner && (
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            mb: 3,
                            bgcolor: theme.palette.primary.main,
                            color: getTextColor(theme.palette.primary.main),
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: '8px'
                        }}
                    >

                        <Box>
                            <Typography variant="h6">
                                Template Schedule Starting {new Date(startDate).toLocaleDateString()}
                            </Typography>
                            <Typography variant="body2">
                                Items from the template "{presetData?.name}" have been placed on your calendar.
                                You can add, remove, or move items before creating the final schedule.
                            </Typography>
                        </Box>
                        <IconButton
                            size="small"
                            onClick={() => setShowBanner(false)}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Paper>
                )}
                {mode === 'create' && calendarItems.length === 0 && showBanner && (
                    <Paper
                        elevation={0}
                        sx={{
                            p: 2,
                            mb: 3,
                            bgcolor: theme.palette.primary.main,
                            color: getTextColor(theme.palette.primary.main),
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderRadius: '8px'
                        }}
                    >
                        <Box>
                            <Typography variant="h6">
                                Click on any day to add content
                            </Typography>
                            <Typography variant="body2">
                                To create a delivery group, add at least one content item to the calendar
                            </Typography>
                        </Box>
                        <IconButton
                            size="small"
                            onClick={() => setShowBanner(false)}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Paper>
                )}


                <Grid container>
                    {renderPaginatedCalendarGrid}
                </Grid>

                {/* Pagination controls - bottom */}
                <PaginationControls />

                {/* Multi-Item Selection Modal */}
                <Dialog
                    open={multiItemSelectState.isOpen && !['course', 'simulation'].includes(activeModal)}
                    onClose={() => setMultiItemSelectState(prev => ({ ...prev, isOpen: false }))}
                    maxWidth="xs"
                    fullWidth
                    PaperProps={{
                        style: { borderRadius: MODAL_BORDER_RADIUS },
                        sx: {
                            bgcolor: 'background.default'
                        }
                    }}
                    keepMounted
                >
                    <DialogTitle>
                        Select Item to Edit
                        <Typography variant="subtitle2" color="textSecondary">
                            {multiItemSelectState.date ? formatDate(multiItemSelectState.date) : ''}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ my: 1 }}>
                            {multiItemSelectState.items.map(item => (
                                <Button
                                    key={item.id}
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        mb: 1,
                                        borderLeft: `6px solid ${contentTypeColors[item.contentType] || '#999'}`,
                                        justifyContent: 'flex-start',
                                        textAlign: 'left',
                                        borderRadius: '8px',
                                        opacity: multiItemSelectState.readOnly ? 0.8 : 1,
                                        bgcolor: 'white',
                                        '&:hover': {
                                            bgcolor: 'white'
                                        }
                                    }}
                                    onClick={() => {
                                        // Only allow editing for future dates
                                        if (!multiItemSelectState.readOnly) {
                                            handleItemClick(item);
                                            setMultiItemSelectState(prev => ({ ...prev, isOpen: false }));
                                        } else {
                                            // For past dates, just show item details but don't allow editing
                                            showNotification("Items on past dates cannot be edited", "info");
                                        }
                                    }}
                                >
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Typography varient="h4" sx={{ textTransform: 'none' }}>{item.contentId}</Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {item.contentType}
                                            {multiItemSelectState.readOnly && " (View only - past date)"}
                                        </Typography>
                                    </Box>
                                </Button>
                            ))}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {!multiItemSelectState.readOnly && (
                            <Button
                                onClick={() => {
                                    // Open add dialog for this date
                                    setModalState(prev => ({
                                        ...prev,
                                        isAddModalOpen: true,
                                        selectedDate: multiItemSelectState.date
                                    }));
                                    setFormState(prev => ({
                                        ...prev,
                                        contentId: '',
                                        contentType: 'Simulation',
                                        scheduledFor: formatDate(multiItemSelectState.date),
                                        selectedContentName: ''
                                    }));
                                    setMultiItemSelectState(prev => ({ ...prev, isOpen: false }));
                                }}
                                color="primary"
                                variant="contained"
                                startIcon={<AddIcon />}
                            >
                                Add New Item
                            </Button>
                        )}
                        <Button
                            onClick={() => setMultiItemSelectState(prev => ({ ...prev, isOpen: false }))}
                            variant="contained"
                            color="secondary"
                        >
                            {multiItemSelectState.readOnly ? 'Close' : 'Cancel'}
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Add Item Modal - With similar selection to JourneyForm */}
                <Dialog
                    open={isAddModalOpen && !['course', 'simulation'].includes(activeModal)}
                    onClose={() => handleCloseModal('isAddModalOpen')}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        style: { borderRadius: MODAL_BORDER_RADIUS },
                        sx: {
                            bgcolor: 'background.default'
                        }
                    }}
                    keepMounted
                >
                    <DialogTitle>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Add Item</Typography>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={!formState.contentId}
                                onClick={handlePreview}
                                startIcon={<VisibilityIcon />}
                            >
                                Preview
                            </Button>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {/* Content Type Selection */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Content Type</InputLabel>
                                    <Select
                                        name="contentType"
                                        value={formState.contentType}
                                        onChange={handleContentTypeChange}
                                        label="Content Type"
                                        required
                                    >
                                        <MenuItem value="Simulation">Simulation</MenuItem>
                                        <MenuItem value="Course">Course</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Content Selection Dropdown */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Select {formState.contentType}</InputLabel>
                                    <Select
                                        name="selectedContentName"
                                        value={formState.selectedContentName}
                                        onChange={handleContentSelection}
                                        label={`Select ${formState.contentType}`}
                                        required
                                    >
                                        {formState.contentType === 'Simulation' && simData && simData.map((sim, index) => (
                                            <MenuItem key={index} value={sim.simulationName}>
                                                {sim.simulationName}
                                            </MenuItem>
                                        ))}
                                        {formState.contentType === 'Course' && courseData && courseData.map((course, index) => (
                                            <MenuItem key={index} value={course.courseName}>
                                                {course.courseName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Date Selection */}
                            <Grid item xs={12}>
                                <TextField
                                    name="scheduledFor"
                                    label="Scheduled For"
                                    type="date"
                                    value={formState.scheduledFor}
                                    onChange={handleFormChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => handleCloseModal('isAddModalOpen')}
                            variant="contained"
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleAddItem}
                            variant="contained"
                            color="primary"
                            disabled={!formState.contentId || !formState.scheduledFor}
                        >
                            Add Item
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* Edit Item Modal */}
                <Dialog
                    open={isEditModalOpen && !['course', 'simulation'].includes(activeModal)}
                    onClose={() => handleCloseModal('isEditModalOpen')}
                    maxWidth="sm"
                    fullWidth
                    PaperProps={{
                        style: { borderRadius: MODAL_BORDER_RADIUS },
                        sx: {
                            bgcolor: 'background.default'
                        }
                    }}
                    keepMounted
                >
                    <DialogTitle>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6">Edit Item</Typography>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                disabled={!formState.contentId}
                                onClick={handlePreview}
                                startIcon={<VisibilityIcon />}
                            >
                                Preview
                            </Button>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            {/* Content Type Selection */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Content Type</InputLabel>
                                    <Select
                                        name="contentType"
                                        value={formState.contentType}
                                        onChange={handleContentTypeChange}
                                        label="Content Type"
                                        required
                                    >
                                        <MenuItem value="Simulation">Simulation</MenuItem>
                                        <MenuItem value="Course">Course</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Content Selection Dropdown */}
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Select {formState.contentType}</InputLabel>
                                    <Select
                                        name="selectedContentName"
                                        value={formState.selectedContentName}
                                        onChange={handleContentSelection}
                                        label={`Select ${formState.contentType}`}
                                        required
                                    >
                                        {formState.contentType === 'Simulation' && simData && simData.map((sim, index) => (
                                            <MenuItem key={index} value={sim.simulationName}>
                                                {sim.simulationName}
                                            </MenuItem>
                                        ))}
                                        {formState.contentType === 'Course' && courseData && courseData.map((course, index) => (
                                            <MenuItem key={index} value={course.courseName}>
                                                {course.courseName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Date Selection */}
                            <Grid item xs={12}>
                                <TextField
                                    name="scheduledFor"
                                    label="Scheduled For"
                                    type="date"
                                    value={formState.scheduledFor}
                                    onChange={handleFormChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteItem}
                            color="error"
                            startIcon={<DeleteIcon />}
                            sx={{ mr: 'auto' }}
                        >
                            Delete
                        </Button>

                        {/* Move button */}
                        <Button
                            onClick={() => handleCloseModal('isEditModalOpen')}
                            variant="contained"
                            color="warning"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleEnterMoveMode}
                            variant="contained"
                            color="secondary"
                            startIcon={<DriveFileMoveIcon />}
                        >
                            Move Item
                        </Button>
                        
                        <Button
                            onClick={handleUpdateItem}
                            variant="contained"
                            color="primary"
                            disabled={!formState.contentId || !formState.scheduledFor}
                        >
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>

                <ContentPackCoursePreviewModal
                    courseId={selectedCourseId || ''}
                    open={activeModal === 'course' && !!selectedCourseId}
                    onClose={handleClosePreview}
                    isStandalone={true}
                />

                <CalendarSimulationPreviewModal
                    simulationId={selectedSimId || ''}
                    open={activeModal === 'simulation' && !!selectedSimId}
                    onClose={handleClosePreview}
                    isStandalone={true}
                />

                <ExportToPresetModal
                    open={exportPresetOpen}
                    onClose={() => setExportPresetOpen(false)}
                    calendarItems={calendarItems}
                    showNotification={showNotification}
                />
            </MainCard>
        </Box>
    );
};

export default ContentDeliveryCalendarWithProvider;