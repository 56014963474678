import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Divider,
    IconButton,
    CircularProgress,
    Snackbar,
    Alert,
    Card,
    CardContent,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    ArrowBack as BackIcon,
    CalendarToday as CalendarTodayIcon
} from '@mui/icons-material';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import UsersTable from '../users/UsersTable';

import {
    useGetDeliveryRequestByIdQuery,
    useCreateDeliveryRequestMutation,
    useUpdateDeliveryRequestMutation
} from '../../store/services/delivery-service';

const initializeNewItem = () => ({
    contentId: '',
    contentType: 'Simulation',
    recipientType: 'User',
    sendToId: '',
    scheduledFor: new Date(Date.now() + 3600000) // 1 hour from now
});

const DeliveryRequestForm = ({ isEditing = false }) => {
    const navigate = useNavigate();
    const { tenancyId, requestId } = useParams();

    // Refs for flatpickr
    const scheduledDateRefs = useRef([]);
    const flatpickrInstances = useRef([]);

    const [formData, setFormData] = useState({
        name: '',
        type: 'DirectOnDemand',
        createdById: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).userId : '',
        deliveryItems: [initializeNewItem()]
    });

    const [errors, setErrors] = useState({});
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [userSelectionOpen, setUserSelectionOpen] = useState(false);
    const [currentItemIndex, setCurrentItemIndex] = useState(null);

    // Queries and mutations
    const { data: requestData, isLoading: isLoadingRequest } = useGetDeliveryRequestByIdQuery(
        { tenancyId, requestId },
        { skip: !isEditing || !requestId }
    );

    const [createDeliveryRequest, { isLoading: isCreating }] = useCreateDeliveryRequestMutation();
    const [updateDeliveryRequest, { isLoading: isUpdating }] = useUpdateDeliveryRequestMutation();

    // Format a date for display
    const formatDateTime = (date) => {
        return date instanceof Date ? flatpickr.formatDate(date, "Y-m-d H:i") : "";
    };

    // Initialize flatpickr instances
    useEffect(() => {
        // Destroy existing instances
        flatpickrInstances.current.forEach(instance => {
            if (instance && typeof instance.destroy === 'function') {
                instance.destroy();
            }
        });

        flatpickrInstances.current = [];

        // Create new instances for each item
        formData.deliveryItems.forEach((item, index) => {
            const element = scheduledDateRefs.current[index];
            if (element) {
                const instance = flatpickr(element, {
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    time_24hr: true,
                    minuteIncrement: 1,
                    defaultDate: item.scheduledFor,
                    onChange: ([date]) => {
                        if (date) {
                            handleItemChange(index, 'scheduledFor', date);
                        }
                    }
                });

                flatpickrInstances.current[index] = instance;
            }
        });

        return () => {
            flatpickrInstances.current.forEach(instance => {
                if (instance && typeof instance.destroy === 'function') {
                    instance.destroy();
                }
            });
        };
    }, [formData.deliveryItems.length]);

    // Ensure we update flatpickr ref array when items change
    const setScheduledDateRef = (element, index) => {
        scheduledDateRefs.current[index] = element;
    };

    // Initialize form with existing data when editing
    useEffect(() => {
        if (isEditing && requestData) {
            const items = requestData.items || [];

            setFormData({
                name: requestData.request.name || '',
                type: requestData.request.type || 'DirectOnDemand',
                createdById: requestData.request.createdById || '',
                deliveryItems: items.length > 0 ? items.map(item => ({
                    id: item.id,
                    contentId: item.contentId || '',
                    contentType: item.contentType || 'Simulation',
                    recipientType: item.recipientType || 'User',
                    sendToId: item.sendToId || '',
                    scheduledFor: new Date(item.scheduledFor)
                })) : [initializeNewItem()]
            });
        }
    }, [isEditing, requestData]);

    // Handle input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Handle item input changes
    const handleItemChange = (index, field, value) => {
        setFormData(prev => {
            const updatedItems = [...prev.deliveryItems];
            updatedItems[index] = {
                ...updatedItems[index],
                [field]: value
            };
            return {
                ...prev,
                deliveryItems: updatedItems
            };
        });
    };

    // Add new item
    const handleAddItem = () => {
        setFormData(prev => ({
            ...prev,
            deliveryItems: [...prev.deliveryItems, initializeNewItem()]
        }));
    };

    // Remove item
    const handleRemoveItem = (index) => {
        setFormData(prev => {
            const updatedItems = [...prev.deliveryItems];
            updatedItems.splice(index, 1);
            return {
                ...prev,
                deliveryItems: updatedItems.length > 0 ? updatedItems : [initializeNewItem()]
            };
        });
    };

    // Open user selection dialog
    const handleOpenUserSelection = (index) => {
        setCurrentItemIndex(index);
        setUserSelectionOpen(true);
    };

    // Handle user selection
    const handleUserSelected = (userId) => {
        if (currentItemIndex !== null) {
            handleItemChange(currentItemIndex, 'sendToId', userId);
            setUserSelectionOpen(false);
        }
    };

    // Validate form
    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        formData.deliveryItems.forEach((item, index) => {
            if (!item.contentId.trim()) {
                newErrors[`item_${index}_contentId`] = 'Content ID is required';
            }

            if (!item.sendToId.trim()) {
                newErrors[`item_${index}_sendToId`] = 'Recipient is required';
            }

            if (!item.scheduledFor) {
                newErrors[`item_${index}_scheduledFor`] = 'Schedule time is required';
            } else if (new Date(item.scheduledFor) < new Date()) {
                newErrors[`item_${index}_scheduledFor`] = 'Schedule time must be in the future';
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setSnackbar({
                open: true,
                message: 'Please fix the errors in the form',
                severity: 'error'
            });
            return;
        }

        try {
            const requestDto = {
                name: formData.name,
                tenancyId: parseInt(tenancyId),
                type: formData.type,
                createdById: formData.createdById,
                deliveryItems: formData.deliveryItems.map(item => ({
                    contentId: item.contentId,
                    contentType: item.contentType,
                    recipientType: item.recipientType,
                    sendToId: item.sendToId,
                    scheduledFor: new Date(item.scheduledFor).toISOString()
                }))
            };

            if (isEditing) {
                // Handle update - prepare update DTO
                const itemUpdates = [];
                const newItems = [];
                const itemIdsToRemove = [];

                // Original items from API (if available)
                const originalItems = requestData?.items || [];

                // Process existing items
                originalItems.forEach(originalItem => {
                    const matchingItem = formData.deliveryItems.find(item =>
                        item.id === originalItem.id
                    );

                    if (!matchingItem) {
                        // Item was removed
                        itemIdsToRemove.push(originalItem.id);
                    } else {
                        // Item was potentially updated
                        const update = {
                            itemId: originalItem.id
                        };

                        let hasChanges = false;

                        if (matchingItem.contentId !== originalItem.contentId) {
                            update.contentId = matchingItem.contentId;
                            hasChanges = true;
                        }

                        if (matchingItem.contentType !== originalItem.contentType) {
                            update.contentType = matchingItem.contentType;
                            hasChanges = true;
                        }

                        if (matchingItem.recipientType !== originalItem.recipientType) {
                            update.recipientType = matchingItem.recipientType;
                            hasChanges = true;
                        }

                        if (matchingItem.sendToId !== originalItem.sendToId) {
                            update.sendToId = matchingItem.sendToId;
                            hasChanges = true;
                        }

                        const originalDate = new Date(originalItem.scheduledFor);
                        const newDate = new Date(matchingItem.scheduledFor);
                        if (originalDate.getTime() !== newDate.getTime()) {
                            update.scheduledFor = newDate.toISOString();
                            hasChanges = true;
                        }

                        if (hasChanges) {
                            itemUpdates.push(update);
                        }
                    }
                });

                // Find new items
                formData.deliveryItems.forEach(item => {
                    if (!item.id) {
                        newItems.push({
                            contentId: item.contentId,
                            contentType: item.contentType,
                            recipientType: item.recipientType,
                            sendToId: item.sendToId,
                            scheduledFor: new Date(item.scheduledFor).toISOString()
                        });
                    }
                });

                const updateDto = {
                    deliveryHubRequestId: requestId,
                    name: formData.name,
                    updatedById: formData.createdById,
                    itemUpdates,
                    newItems,
                    itemIdsToRemove
                };

                await updateDeliveryRequest({
                    tenancyId,
                    requestId,
                    requestData: updateDto
                }).unwrap();

                setSnackbar({
                    open: true,
                    message: 'Delivery request updated successfully',
                    severity: 'success'
                });
            } else {
                // Handle create
                await createDeliveryRequest({
                    tenancyId,
                    requestData: requestDto
                }).unwrap();

                setSnackbar({
                    open: true,
                    message: 'Delivery request created successfully',
                    severity: 'success'
                });
            }

            // Navigate back after short delay
            setTimeout(() => {
                navigate(`/delivery`);
            }, 1500);
        } catch (err) {
            setSnackbar({
                open: true,
                message: `Failed to ${isEditing ? 'update' : 'create'} delivery request: ${err.data?.message || 'Unknown error'}`,
                severity: 'error'
            });
        }
    };

    if (isEditing && isLoadingRequest) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton
                    onClick={() => navigate('/delivery')}
                    sx={{ mr: 2 }}
                >
                    <BackIcon />
                </IconButton>
                <Typography variant="h5" component="h1">
                    {isEditing ? 'Edit Delivery Request' : 'Create Delivery Request'}
                </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={!!errors.name}
                            helperText={errors.name}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <FormControl fullWidth>
                            <InputLabel>Request Type</InputLabel>
                            <Select
                                name="type"
                                value={formData.type}
                                label="Request Type"
                                onChange={handleInputChange}
                            >
                                <MenuItem value="Template">Template</MenuItem>
                                <MenuItem value="RandomizedSims">Randomized Sims</MenuItem>
                                <MenuItem value="DirectOnDemand">Direct On Demand</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{ my: 4 }}>
                    <Typography variant="h6" gutterBottom>
                        Delivery Items
                    </Typography>
                    <Divider sx={{ mb: 2 }} />

                    {formData.deliveryItems.map((item, index) => (
                        <Card key={index} variant="outlined" sx={{ mb: 3 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={11}>
                                        <Typography variant="subtitle1" component="h3">
                                            Item {index + 1}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleRemoveItem(index)}
                                            disabled={formData.deliveryItems.length <= 1}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Content ID"
                                            value={item.contentId}
                                            onChange={(e) => handleItemChange(index, 'contentId', e.target.value)}
                                            error={!!errors[`item_${index}_contentId`]}
                                            helperText={errors[`item_${index}_contentId`]}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Content Type</InputLabel>
                                            <Select
                                                value={item.contentType}
                                                label="Content Type"
                                                onChange={(e) => handleItemChange(index, 'contentType', e.target.value)}
                                            >
                                                <MenuItem value="Simulation">Simulation</MenuItem>
                                                <MenuItem value="Course">Course</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel>Recipient Type</InputLabel>
                                            <Select
                                                value={item.recipientType}
                                                label="Recipient Type"
                                                onChange={(e) => handleItemChange(index, 'recipientType', e.target.value)}
                                            >
                                                <MenuItem value="User">User</MenuItem>
                                                <MenuItem value="Group">Group</MenuItem>
                                                <MenuItem value="Tenancy">Tenancy</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <TextField
                                                fullWidth
                                                label="Send To ID"
                                                value={item.sendToId}
                                                onChange={(e) => handleItemChange(index, 'sendToId', e.target.value)}
                                                error={!!errors[`item_${index}_sendToId`]}
                                                helperText={errors[`item_${index}_sendToId`]}
                                                required
                                            />
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleOpenUserSelection(index)}
                                                sx={{ whiteSpace: 'nowrap' }}
                                            >
                                                Select User
                                            </Button>
                                        </Box>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label="Scheduled For"
                                            inputRef={(el) => setScheduledDateRef(el, index)}
                                            defaultValue={formatDateTime(item.scheduledFor)}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errors[`item_${index}_scheduledFor`]}
                                            helperText={errors[`item_${index}_scheduledFor`]}
                                            required
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CalendarTodayIcon
                                                            sx={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                const instance = flatpickrInstances.current[index];
                                                                if (instance) {
                                                                    instance.open();
                                                                }
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}

                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddItem}
                    >
                        Add Item
                    </Button>
                </Box>

                <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={() => navigate('/delivery')}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isCreating || isUpdating}
                    >
                        {(isCreating || isUpdating) ? (
                            <CircularProgress size={24} />
                        ) : (
                            isEditing ? 'Update' : 'Create'
                        )}
                    </Button>
                </Box>
            </form>

            {/* User Selection Dialog */}
            <Dialog
                open={userSelectionOpen}
                onClose={() => setUserSelectionOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Select User</DialogTitle>
                <DialogContent>
                    <UsersTable
                        type="select"
                        onUserSelected={handleUserSelected}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUserSelectionOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

DeliveryRequestForm.propTypes = {
    isEditing: PropTypes.bool
};

export default DeliveryRequestForm;